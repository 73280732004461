import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import CheckoutSteps from "./CheckoutSteps";
import { useDispatch } from "react-redux";
import { clearOrder } from "../../actions/orderActions";

function Complete() {
  // Dispatch and Navigate
  const dispatch = useDispatch();

  // useEffects
  useEffect(() => {
    const nav = document.querySelector(".nav-contain");
    nav.className = "nav-contain nav-sticky container-fluid section-bg";
  }, []);

  useEffect(() => {
    dispatch(clearOrder());
  }, [dispatch]);

  return (
    <Container fluid className="section-contain">
      <CheckoutSteps step4 />
      <Row>
        <Col lg={12}>
          <h1 className="header">Thanks for your order!</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Link to="/account/profile">
            You can download the fonts from your account.
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default Complete;
