import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Offcanvas, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector } from "react-redux";

function Navigation() {
  // Selectors
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const cartVoucherValid = useSelector((state) => state.cartVoucherValid);
  const { voucherValid } = cartVoucherValid;

  const cartTax = useSelector((state) => state.cartTax);
  const { tax } = cartTax;

  const cartVatValid = useSelector((state) => state.cartVatValid);
  const { vatValid } = cartVatValid;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const cartBillingInfo = useSelector((state) => state.cartBillingInfo);
  const { billingInfo } = cartBillingInfo;

  // State Etc
  // Off Canvas
  const [showNav, setShowNav] = useState(false);
  const handleCloseNav = () => setShowNav(false);
  const toggleShowNav = () => setShowNav((s) => !s);
  const backdropShowNav = false;
  const bodyScrollNav = true;

  // Cart Totals
  const cartLength = cartItems.length;
  let cartSub = cartItems.reduce((acc, item) => acc + item.price, 0).toFixed(2);
  let cartVoucherRate = parseFloat(voucherValid.discount);
  let cartVoucherTotal = (
    parseFloat(cartSub) * parseFloat(cartVoucherRate)
  ).toFixed(2);
  cartSub = (parseFloat(cartSub) - parseFloat(cartVoucherTotal)).toFixed(2);
  let cartTaxTotal;
  if (vatValid && vatValid.valid && billingInfo.country !== "Netherlands") {
    cartTaxTotal = (0).toFixed(2);
  } else {
    cartTaxTotal = (parseFloat(cartSub) * parseFloat(tax.vatRate)).toFixed(2);
  }
  const cartTotal = (parseFloat(cartSub) + parseFloat(cartTaxTotal)).toFixed(2);

  return (
    <Container fluid className="nav-contain nav-sticky">
      <Helmet>
        <title>CakeType</title>
        <meta
          name="description"
          content="CakeType is the boutique type foundry of Pieter van Rosmalen."
        />
      </Helmet>
      <nav id="nav">
        <div className="navigation-large d-none d-md-flex justify-content-between">
          <div className="section-nav">
            <Link to="/">Typefaces</Link>
            <span className="pipe">|</span>
            <Link to="/about">About</Link>
            <span className="pipe">|</span>
            <Link to="/support">Support</Link>
          </div>
          <div className="section-nav">
            {cartLength > 0 ? (
              <Link to="/cart">
                Cart: {cartLength}&nbsp;&#8211;&nbsp;€ {cartTotal}
              </Link>
            ) : (
              <Link to="/cart">Cart</Link>
            )}
            <span className="pipe">|</span>
            {userInfo ? (
              <Link to="/account/profile">Account</Link>
            ) : (
              <Link to="/login">Log in</Link>
            )}
          </div>
        </div>
        <div className="navigation-small d-flex justify-content-between d-md-none">
          <nav className="navbar navbar-light">
            {cartLength > 0 ? (
              <LinkContainer to="/cart">
                <Button variant="link">
                  Cart: {cartLength}&nbsp;&#8211;&nbsp;€ {cartTotal}
                </Button>
              </LinkContainer>
            ) : (
              <LinkContainer to="/cart">
                <Button variant="link">Cart</Button>
              </LinkContainer>
            )}
          </nav>
          <div>
            <Offcanvas
              show={showNav}
              onHide={handleCloseNav}
              backdrop={backdropShowNav}
              scroll={bodyScrollNav}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>CakeType menu</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <ul className="nav-overflow-list">
                  <li onClick={handleCloseNav}>
                    <Link to="/">Typefaces</Link>
                  </li>
                  <li onClick={handleCloseNav}>
                    <Link to="/about" className="fonts-nav">
                      About
                    </Link>
                  </li>
                  <li onClick={handleCloseNav}>
                    <Link to="/support" className="fonts-nav">
                      Support
                    </Link>
                  </li>
                  <li onClick={handleCloseNav}>
                    {userInfo ? (
                      <Link to="/account/profile">Account</Link>
                    ) : (
                      <Link to="/login">Log in</Link>
                    )}
                  </li>
                </ul>
              </Offcanvas.Body>
            </Offcanvas>
            <nav className="navbar navbar-light">
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleShowNav}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </nav>
          </div>
        </div>
      </nav>
    </Container>
  );
}

export default Navigation;
