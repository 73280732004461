import {
  CART_ADD_FONT,
  CART_ADD_PART,
  CART_ADD_TRIAL,
  CART_REMOVE_ITEM,
  CART_REMOVE_ALL,
  CART_CLEAR_ITEMS,
  CART_DISCOUNT,
  CART_DISCOUNT_RESET,
  CART_VOUCHER_VALID_REQUEST,
  CART_VOUCHER_VALID_SUCCESS,
  CART_VOUCHER_VALID_RESET,
  CART_TAX,
  CART_TAX_RESET,
  CART_VAT,
  CART_VAT_RESET,
  CART_COUNTRY,
  CART_COUNTRY_RESET,
  CART_BILLING_INFO,
  CART_BILLING_INFO_RESET,
  CART_VAT_VALID_REQUEST,
  CART_VAT_VALID_SUCCESS,
  CART_VAT_VALID_RESET,
  CART_LICENSE_HOLDER,
  CART_LICENSE_HOLDER_RESET,
  CART_PAYMENT_METHOD,
  CART_PAYMENT_METHOD_RESET,
} from "../constants/cartConstants";

export const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case CART_ADD_FONT:
      const font = action.payload;
      return { ...state, cartItems: [...state.cartItems, font] };
    case CART_ADD_PART:
      const part = action.payload;
      return { ...state, cartItems: [...state.cartItems, part] };
    case CART_ADD_TRIAL:
      const trial = action.payload;
      return { ...state, cartItems: [...state.cartItems, trial] };
    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.slug !== action.payload),
      };
    case CART_REMOVE_ALL:
      return {
        ...state,
        cartItems: [],
      };
    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItems: [],
      };
    default:
      return state;
  }
};

export const cartDiscountReducer = (state = { discount: {} }, action) => {
  switch (action.type) {
    case CART_DISCOUNT:
      return {
        ...state,
        discount: action.payload,
      };
    case CART_DISCOUNT_RESET:
      return {
        ...state,
        discount: [],
      };
    default:
      return state;
  }
};

export const cartVoucherValidReducer = (
  state = { voucherValid: {} },
  action
) => {
  switch (action.type) {
    case CART_VOUCHER_VALID_REQUEST:
      return { loading: true, ...state };
    case CART_VOUCHER_VALID_SUCCESS:
      return { loading: false, voucherValid: action.payload };
    case CART_VOUCHER_VALID_RESET:
      return {
        ...state,
        voucherValid: [],
      };
    default:
      return state;
  }
};

export const cartTaxReducer = (state = { tax: {} }, action) => {
  switch (action.type) {
    case CART_TAX:
      return {
        ...state,
        tax: action.payload,
      };
    case CART_TAX_RESET:
      return {
        ...state,
        tax: [],
      };
    default:
      return state;
  }
};

export const cartVatReducer = (state = { vat: {} }, action) => {
  switch (action.type) {
    case CART_VAT:
      return {
        ...state,
        vat: action.payload,
      };
    case CART_VAT_RESET:
      return {
        ...state,
        vat: [],
      };
    default:
      return state;
  }
};

export const cartCountryReducer = (state = { countries: [] }, action) => {
  switch (action.type) {
    case CART_COUNTRY:
      return {
        ...state,
        countries: action.payload,
      };
    case CART_COUNTRY_RESET:
      return {
        ...state,
        countries: [],
      };
    default:
      return state;
  }
};

export const cartBillingInfoReducer = (state = { billingInfo: {} }, action) => {
  switch (action.type) {
    case CART_BILLING_INFO:
      return {
        ...state,
        billingInfo: action.payload,
      };
    case CART_BILLING_INFO_RESET:
      return {
        ...state,
        billingInfo: [],
      };
    default:
      return state;
  }
};

export const cartVatValidReducer = (state = { vatValid: {} }, action) => {
  switch (action.type) {
    case CART_VAT_VALID_REQUEST:
      return { loading: true, ...state };
    case CART_VAT_VALID_SUCCESS:
      return { loading: false, vatValid: action.payload };
    case CART_VAT_VALID_RESET:
      return {
        ...state,
        vatValid: [],
      };
    default:
      return state;
  }
};

export const cartLicenseHolderReducer = (
  state = { licenseHolder: {} },
  action
) => {
  switch (action.type) {
    case CART_LICENSE_HOLDER:
      return {
        ...state,
        licenseHolder: action.payload,
      };
    case CART_LICENSE_HOLDER_RESET:
      return {
        ...state,
        licenseHolder: [],
      };
    default:
      return state;
  }
};

export const cartPaymentMethodReducer = (
  state = { paymentMethod: {} },
  action
) => {
  switch (action.type) {
    case CART_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case CART_PAYMENT_METHOD_RESET:
      return {
        ...state,
        licenseHolder: [],
      };
    default:
      return state;
  }
};
