import { Container, Row, Col } from "react-bootstrap";

function ResetComplete() {
  return (
    <Container fluid className="section-contain">
      <Row>
        <Col>
          <h1 className="header">Reset complete</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Your password has been updated.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetComplete;
