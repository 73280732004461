import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { getUserDetails, updateUserDetails } from "../../actions/userActions";
import { USER_UPDATE_DETAILS_RESET } from "../../constants/userConstants";
import Loader from "../main/Loader";
import Message from "../main/Message";

function ChangeDetails() {
  // Selectors
  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateDetails = useSelector((state) => state.userUpdateDetails);
  const { success } = userUpdateDetails;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");

  // useEffects
  useEffect(() => {
    const nav = document.querySelector(".nav-contain");
    nav.className = "nav-contain nav-sticky container-fluid section-bg";
  }, []);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login", { replace: true });
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_DETAILS_RESET });
        dispatch(getUserDetails("profile"));
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }
  }, [dispatch, navigate, userInfo, user, success]);

  useEffect(() => {
    if (success) {
      setMessageSuccess("You have successfully changed your details.");
    }
  }, [success]);

  // Submit
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateUserDetails({
        id: user.id,
        name: name,
        email: email,
      })
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {messageSuccess && (
            <Message variant="success">{messageSuccess}</Message>
          )}
          {error && <Message variant="danger">{error}</Message>}
          <Container fluid className="section-contain">
            <Form onSubmit={submitHandler}>
              <Row>
                <Col md={12}>
                  <h1 className="header">Change details</h1>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Full name</Form.Label>
                    <Form.Control
                      required
                      type="name"
                      placeholder=""
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className="form-link">
                  <Button type="submit" variant="link">
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </>
      )}
    </>
  );
}

export default ChangeDetails;
