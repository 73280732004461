import "./assets/scss/style.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import CookieConsent from "react-cookie-consent";

import Logo from "./components/main/Logo";
import Navigation from "./components/main/Navigation";
import List from "./components/main/List";
import Footer from "./components/main/Footer";

import Home from "./components/home/Home";
import Font from "./components/font/Font";
import About from "./components/section/About";
import Support from "./components/section/Support";
import NotFound from "./components/section/NotFound";
import Cart from "./components/cart/Cart";
import Login from "./components/account/Login";
import LoginTrial from "./components/account/LoginTrial";
import LoginCheckout from "./components/account/LoginCheckout";
import Register from "./components/account/Register";
import ActivateSent from "./components/account/ActivateSent";
import ActivateInit from "./components/account/ActivateInit";
import ActivateComplete from "./components/account/ActivateComplete";
import ResetSubmit from "./components/account/ResetSubmit";
import ResetSent from "./components/account/ResetSent";
import ResetInit from "./components/account/ResetInit";
import ResetComplete from "./components/account/ResetComplete";
import Profile from "./components/account/Profile";
import ChangeDetails from "./components/account/ChangeDetails";
import ChangePassword from "./components/account/ChangePassword";
import Download from "./components/account/Download";
import BillingInfo from "./components/checkout/BillingInfo";
import LicenseHolder from "./components/checkout/LicenseHolder";
import PaymentMethod from "./components/checkout/PaymentMethod";
import PayPalIntent from "./components/checkout/PayPalIntent";
import StripeIntent from "./components/checkout/StripeIntent";
import Complete from "./components/checkout/Complete";
import OrderError from "./components/checkout/OrderError";
import TrialLicenseHolder from "./components/checkout/TrialLicenseHolder";
import TrialIntent from "./components/checkout/TrialIntent";
import TrialComplete from "./components/checkout/TrialComplete";
import Invoice from "./components/account/Invoice";
import OrderDetails from "./components/mgmt/OrderDetails";

const payPalOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "EUR",
  intent: "capture",
};

function App() {
  return (
    <PayPalScriptProvider options={payPalOptions}>
      <BrowserRouter>
        <Logo />
        <Navigation />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/fonts" element={<Home />} />
          <Route path="/font/:slug" element={<Font />} />
          <Route path="/about" element={<About />} />
          <Route path="/support" element={<Support />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/activate-sent" element={<ActivateSent />} />
          <Route path="/activate/:uid/:token" element={<ActivateInit />} />
          <Route path="/activate-complete" element={<ActivateComplete />} />
          <Route path="/reset-request" element={<ResetSubmit />} />
          <Route path="/reset-sent" element={<ResetSent />} />
          <Route path="/reset/:uid/:token" element={<ResetInit />} />
          <Route path="/reset-complete" element={<ResetComplete />} />
          <Route path="/account/profile" element={<Profile />} />
          <Route path="/account/change-details" element={<ChangeDetails />} />
          <Route path="/account/change-password" element={<ChangePassword />} />
          <Route
            path="/account/download/:name/:slug/:file"
            element={<Download />}
          />
          <Route path="/account/invoice/:orderId" element={<Invoice />} />
          <Route path="/checkout/billing-info" element={<BillingInfo />} />
          <Route path="/checkout/license-holder" element={<LicenseHolder />} />
          <Route path="/checkout/payment-method" element={<PaymentMethod />} />
          <Route path="/checkout/login" element={<LoginCheckout />} />
          <Route path="/checkout/payment-paypal" element={<PayPalIntent />} />
          <Route path="/checkout/payment-cc" element={<StripeIntent />} />
          <Route path="/checkout/complete" element={<Complete />} />
          <Route path="/checkout/order-error" element={<OrderError />} />
          <Route
            path="/checkout/trial/license-holder"
            element={<TrialLicenseHolder />}
          />
          <Route path="/checkout/trial/login" element={<LoginTrial />} />
          <Route
            path="/checkout/trial/submit-order"
            element={<TrialIntent />}
          />
          <Route path="/checkout/trial/complete" element={<TrialComplete />} />
          <Route path="/admin/order/:orderId" element={<OrderDetails />} />
        </Routes>
        <List />
        <Footer />
        <CookieConsent
          style={{ background: "black" }}
          buttonStyle={{ background: "white", color: "black" }}
        >
          This website uses cookies to enhance the user experience.{" "}
          <Link to="/support#privacy-policy">
            More about our privacy and cookies policy.
          </Link>
        </CookieConsent>
      </BrowserRouter>
    </PayPalScriptProvider>
  );
}

export default App;
