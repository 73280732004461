import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import TrialSteps from "./TrialSteps";
import { useDispatch } from "react-redux";
import { CART_CLEAR_ITEMS } from "../../constants/cartConstants";
import { ORDER_TRIAL_RESET } from "../../constants/orderConstants";

function TrialComplete() {
  // Dispatch and Navigate
  const dispatch = useDispatch();

  // useEffects
  useEffect(() => {
    const nav = document.querySelector(".nav-contain");
    nav.className = "nav-contain nav-sticky container-fluid section-bg";
  }, []);

  useEffect(() => {
    dispatch({ type: CART_CLEAR_ITEMS });
    dispatch({ type: ORDER_TRIAL_RESET });
  }, [dispatch]);

  useEffect(() => {
    localStorage.removeItem("cartItems");
  }, []);

  return (
    <Container fluid className="section-contain">
      <TrialSteps step3 />
      <Row>
        <Col lg={12}>
          <h1 className="header">Thanks for your order!</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Link to="/account/profile">
            You can download the trial fonts from your account.
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default TrialComplete;
