import axios from "axios";
import { HOST, API } from "../constants/apiConstants";
import {
  ABOUT_LIST_REQUEST,
  ABOUT_LIST_SUCCESS,
  ABOUT_LIST_FAIL,
} from "../constants/aboutConstants";

export const listAbouts = () => async (dispatch) => {
  try {
    dispatch({ type: ABOUT_LIST_REQUEST });
    const endpoint = `${HOST}${API}`;
    const aboutListUrl = `${endpoint}/abouts/`;
    const { data } = await axios.get(aboutListUrl);

    dispatch({ type: ABOUT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ABOUT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
