import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { resetPassword } from "../../actions/userActions";
import { useParams } from "react-router-dom";
import Loader from "../main/Loader";
import Message from "../main/Message";

function ResetInit() {
  // Selectors
  const userReset = useSelector((state) => state.userReset);
  const { error, loading, success } = userReset;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [messageFail, setMessageFail] = useState("");
  const { uid, token } = useParams();

  // useEffects
  useEffect(() => {
    if (success) {
      navigate("/reset-complete", { replace: true });
    }
  }, [navigate, success]);

  // Submit
  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessageFail("Passwords do not match");
    } else {
      dispatch(
        resetPassword({
          uid: uid,
          token: token,
          password: password,
        })
      );
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {messageFail && <Message variant="danger">{messageFail}</Message>}
          {error && <Message variant="danger">{error}</Message>}
          <Container fluid className="section-contain">
            <Form onSubmit={submitHandler}>
              <Row>
                <Col md={12}>
                  <h1 className="header">Reset password</h1>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      placeholder=""
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="passwordConfirm">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      placeholder=""
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className="form-link">
                  <Button type="submit" variant="link">
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </>
      )}
    </>
  );
}

export default ResetInit;
