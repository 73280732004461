import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";

function Footer() {
  // State Etc
  const [date, setDate] = useState();
  const getYear = () => setDate(new Date().getFullYear());

  // useEffects
  useEffect(() => {
    getYear();
  }, []);

  return (
    <Container fluid className="footer-contain">
      <div className="footer-info text-center">
        <p>
          Copyright 2004, {date} CakeType – all rights reserved
          <br />
          This website is built by <a href="https://tiotype.com/">Tiotype</a>
        </p>
      </div>
    </Container>
  );
}

export default Footer;
