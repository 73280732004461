import { Container, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Logo = () => {
  return (
    <Container fluid className="logo-contain">
      <Row>
        <Col>
          <LinkContainer to="/">
            <img
              src="/images/logo/CKTP_logo.svg"
              className="img-fluid"
              alt="CakeType logo"
            />
          </LinkContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default Logo;
