import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Offcanvas, Card } from "react-bootstrap";

function CheckoutSummary() {
  // Selectors
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const cartVoucherValid = useSelector((state) => state.cartVoucherValid);
  const { voucherValid } = cartVoucherValid;

  const cartTax = useSelector((state) => state.cartTax);
  const { tax } = cartTax;

  const cartVatValid = useSelector((state) => state.cartVatValid);
  const { vatValid } = cartVatValid;

  const cartBillingInfo = useSelector((state) => state.cartBillingInfo);
  const { billingInfo } = cartBillingInfo;

  // State Etc
  // Off Canvas
  const [showSumm, setShowSumm] = useState(false);
  const handleCloseSumm = () => setShowSumm(false);
  const toggleShowSumm = () => setShowSumm((s) => !s);
  const backdropShowSumm = false;
  const bodyScrollSumm = true;
  const placementSumm = "end";

  // Cart Totals
  const cartLength = cartItems.length;
  let cartSub = cartItems.reduce((acc, item) => acc + item.price, 0).toFixed(2);
  let cartVoucherRate = parseFloat(voucherValid.discount);
  let cartVoucherTotal = (
    parseFloat(cartSub) * parseFloat(cartVoucherRate)
  ).toFixed(2);
  cartSub = (parseFloat(cartSub) - parseFloat(cartVoucherTotal)).toFixed(2);
  let cartTaxTotal;
  let cartTaxPercent;
  if (vatValid && vatValid.valid && billingInfo.country !== "Netherlands") {
    cartTaxTotal = (0).toFixed(2);
    cartTaxPercent = 0;
  } else {
    cartTaxTotal = (parseFloat(cartSub) * parseFloat(tax.vatRate)).toFixed(2);
    cartTaxPercent = parseFloat(100 * parseFloat(tax.vatRate));
  }
  const cartTotal = (parseFloat(cartSub) + parseFloat(cartTaxTotal)).toFixed(2);

  return (
    <div>
      <button type="button" className="btn btn-link" onClick={toggleShowSumm}>
        Cart summary: {cartLength}&nbsp;&#8211;&nbsp;€ {cartTotal}
      </button>
      <Offcanvas
        show={showSumm}
        onHide={handleCloseSumm}
        backdrop={backdropShowSumm}
        scroll={bodyScrollSumm}
        placement={placementSumm}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Cart summary</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container className="summary-section" fluid>
            {cartItems.map((cartItem, i) => (
              <Card
                key={i}
                className={i === 0 ? "cart-card" : "cart-part-card"}
              >
                <Card.Body>
                  <Row>
                    <Col xs={5} className="my-auto">
                      {cartItem.cartName}
                    </Col>
                    <Col xs={3} className="my-auto">
                      {cartItem.calcName}
                    </Col>
                    <Col xs={4} className="my-auto text-end">
                      € {cartItem.price.toFixed(2)}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
            {voucherValid && voucherValid.voucher_id !== 0 && (
              <Card className="cart-part-card">
                <Card.Body>
                  <Row>
                    <Col xs={8} className="my-auto">
                      Voucher {Math.floor(cartVoucherRate * 100)} %
                    </Col>
                    <Col xs={4} className="my-auto text-end">
                      &minus; € {cartVoucherTotal}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}
            <Card className="cart-part-card">
              <Card.Body>
                <Row>
                  <Col xs={8} className="my-auto">
                    Subtotal
                  </Col>
                  <Col xs={4} className="my-auto text-end">
                    € {cartSub}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="cart-vat-card">
              <Card.Body>
                <Row>
                  <Col xs={8} className="my-auto">
                    VAT {cartTaxPercent} %
                  </Col>
                  <Col xs={4} className="my-auto text-end">
                    € {cartTaxTotal}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="cart-total-card">
              <Card.Body>
                <Row>
                  <Col xs={8} className="my-auto">
                    Total
                  </Col>
                  <Col xs={4} className="my-auto text-end">
                    € {cartTotal}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default CheckoutSummary;
