import React, { useState, useEffect, useRef } from "react";

const SpecBig = ({ home }) => {
  // State Etc
  const [count, setCount] = useState(0);
  const [specOver, setSpecOver] = useState(false);
  const [nameOver, setNameOver] = useState(false);
  let intervalRef = useRef();

  // useEffects
  // specOver
  useEffect(() => {
    const body = document.querySelector("body");
    const nav = document.querySelector(".nav-contain");
    if (specOver) {
      body.className = home.slug + "-bg";
      nav.className =
        "nav-contain nav-sticky container-fluid " + home.slug + "-bg";
      setCount(1);
      intervalRef.current = setInterval(() => {
        setCount((prevCount) => (prevCount + 1) % home.specs.length);
      }, 1000);
      return () => clearInterval(intervalRef.current);
    } else {
      body.className = "section-bg";
      nav.className = "nav-contain nav-sticky container-fluid section-bg";
      setCount(0);
    }
  }, [specOver, home, home.specs, intervalRef]);

  // nameOver
  useEffect(() => {
    const body = document.querySelector("body");
    const nav = document.querySelector(".nav-contain");
    if (nameOver) {
      body.className = home.slug + "-bg";
      nav.className =
        "nav-contain nav-sticky container-fluid " + home.slug + "-bg";
    } else {
      body.className = "section-bg";
      nav.className = "nav-contain nav-sticky container-fluid section-bg";
    }
  }, [nameOver, home]);

  // onSpec
  const onSpec = (slug) => {
    clearInterval(intervalRef.current);
    const path = "/font/" + slug;
    // setCount(0);
    window.location.href = path;
  };

  return (
    <>
      <div
        className="spec-contain"
        onMouseOver={() => setSpecOver(true)}
        onMouseOut={() => setSpecOver(false)}
      >
        {home.specs.length ? (
          <div onClick={() => onSpec(home.slug)}>
            <img
              src={"/images/home/" + home.slug + "/" + home.specs[count].source}
              className="img-fluid"
              alt={home.specs[count].slug}
            />
          </div>
        ) : (
          <img
            src={"/images/home/default/CakeType_home_CakeMono_06.svg"}
            className="img-fluid"
            alt="default"
            onClick={() => onSpec(home.slug)}
          />
        )}
      </div>
      <div
        className="home-font-info"
        onMouseOver={() => setNameOver(true)}
        onMouseOut={() => setNameOver(false)}
      >
        {home.status && (
          <>
            <span className="new">&nbsp;{home.status}&nbsp;</span>
          </>
        )}
        <a href={"/font/" + home.slug}>{home.name}</a>
        <span className="pipe">|</span>
        {home.styles}
        <span className="pipe">|</span>
        {home.price}
        <span className="pipe">|</span>
        {home.system}
      </div>
    </>
  );
};

export default SpecBig;
