import {
  MGMT_ORDER_REQUEST,
  MGMT_ORDER_SUCCESS,
  MGMT_ORDER_FAIL,
} from "../constants/mgmtConstants";

export const mgmtOrderReducer = (
  state = { loading: true, orderItems: [], billingInfo: {}, licenseHolder: {} },
  action
) => {
  switch (action.type) {
    case MGMT_ORDER_REQUEST:
      return { ...state, loading: true };
    case MGMT_ORDER_SUCCESS:
      return { loading: false, order: action.payload };
    case MGMT_ORDER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
