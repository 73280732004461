import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  saveCountries,
  removeItem,
  saveVoucherValid,
} from "../../actions/cartActions";
import { createGlobalStyle } from "styled-components";
import VoucherSpinner from "./VoucherSpinner";
import VoucherValid from "./VoucherValid";
import VoucherExpired from "./VoucherExpired";
import VoucherInvalid from "./VoucherInvalid";

// Global Styles
const WoffFont = createGlobalStyle`.${(props) =>
  props.cartItem.fontClass}{font-family: "${(props) => props.cartItem.name}";}`;

function Cart() {
  // Selectors
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const cartVoucherValid = useSelector((state) => state.cartVoucherValid);
  const { loading, voucherValid } = cartVoucherValid;

  const cartTax = useSelector((state) => state.cartTax);
  const { tax } = cartTax;

  const cartVatValid = useSelector((state) => state.cartVatValid);
  const { vatValid } = cartVatValid;

  const cartBillingInfo = useSelector((state) => state.cartBillingInfo);
  const { billingInfo } = cartBillingInfo;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const [cartDefault, setCartDefault] = useState([]);
  const [cartAdded, setCartAdded] = useState([]);
  const [unifiedCheck, setUnifiedCheck] = useState([]);
  const [voucherCode, setVoucherCode] = useState(voucherValid.code);

  // Cart Totals
  let cartSub = cartItems.reduce((acc, item) => acc + item.price, 0).toFixed(2);
  let cartVoucherRate = parseFloat(voucherValid.discount);
  let cartVoucherTotal = (
    parseFloat(cartSub) * parseFloat(cartVoucherRate)
  ).toFixed(2);
  cartSub = (parseFloat(cartSub) - parseFloat(cartVoucherTotal)).toFixed(2);
  let cartTaxTotal;
  let cartTaxPercent;
  if (vatValid && vatValid.valid && billingInfo.country !== "Netherlands") {
    cartTaxTotal = (0).toFixed(2);
    cartTaxPercent = 0;
  } else {
    cartTaxTotal = (parseFloat(cartSub) * parseFloat(tax.vatRate)).toFixed(2);
    cartTaxPercent = parseFloat(100 * parseFloat(tax.vatRate));
  }
  const cartTotal = (parseFloat(cartSub) + parseFloat(cartTaxTotal)).toFixed(2);

  // useEffects
  useEffect(() => {
    const nav = document.querySelector(".nav-contain");
    nav.className = "nav-contain nav-sticky container-fluid section-bg";
  }, []);

  // Set Countries
  useEffect(() => {
    dispatch(saveCountries());
  }, [dispatch]);

  // Set Font Cart Status
  useEffect(() => {
    if (cartItems.find((ci) => ci.calcType === "unified")) {
      setUnifiedCheck(true);
    } else {
      setUnifiedCheck(false);
    }
  }, [cartItems]);

  // Set Cart Status
  useEffect(() => {
    if (cartItems.length > 0) {
      let updatedCartDefault = "d-none";
      let updatedCartAdded = "d-block";
      setCartDefault(updatedCartDefault);
      setCartAdded(updatedCartAdded);
    } else {
      let updatedCartDefault = "d-block";
      let updatedCartAdded = "d-none";
      setCartDefault(updatedCartDefault);
      setCartAdded(updatedCartAdded);
    }
  }, [cartItems]);

  // Remove Item
  const onRemoveItem = (fontSlug) => {
    dispatch(removeItem(fontSlug));
  };

  // Unified Checkout
  const unifiedCheckoutHandler = () => {
    if (userInfo) {
      navigate("/checkout/billing-info", { replace: true });
    } else {
      navigate("/checkout/login", { replace: true });
    }
  };

  // Trial Checkout
  const trialCheckoutHandler = () => {
    if (userInfo) {
      navigate("/checkout/trial/license-holder", { replace: true });
    } else {
      navigate("/checkout/trial/login", { replace: true });
    }
  };

  // On Voucher Check
  const onVoucherCheck = () => {
    dispatch(saveVoucherValid(voucherCode));
  };

  return (
    <>
      <Container fluid className="section-contain">
        <Row>
          <Col>
            <h1 className="header">Cart</h1>
          </Col>
        </Row>
        <Container className={"cart-section " + cartDefault} fluid>
          <Card className="cart-card">
            <Card.Body>
              <Row>
                <Col lg={12}>Cart is empty</Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
        <Container className={"cart-section " + cartAdded} fluid>
          {cartItems.map((cartItem, i) => (
            <div key={i}>
              <WoffFont cartItem={cartItem} />
              <Card className={i === 0 ? "cart-card" : "cart-part-card"}>
                <Card.Body>
                  <Row>
                    <Col
                      lg={6}
                      className={cartItem.fontClass + " my-auto cart-font-name"}
                    >
                      {cartItem.cartName}
                    </Col>
                    <Col lg={3} className="my-auto">
                      {cartItem.calcName}
                    </Col>
                    <Col lg={1} className="my-auto">
                      € {cartItem.price.toFixed(2)}
                    </Col>
                    <Col lg={2} className="my-auto text-end d-none d-lg-block">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => onRemoveItem(cartItem.slug)}
                      >
                        Remove
                      </button>
                    </Col>
                    <Col lg={2} className="my-auto d-lg-none">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => onRemoveItem(cartItem.slug)}
                      >
                        Remove
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          ))}
          <Card className="cart-part-card">
            <Card.Body>
              <Row>
                <Col lg={6} className="my-auto cart-fill d-none d-lg-block">
                  Voucher
                </Col>
                <Col lg={3} className="my-auto">
                  <Form.Group className="" controlId="voucher">
                    <Form.Control
                      type="text"
                      placeholder="Voucher"
                      value={voucherCode ? voucherCode : ""}
                      onChange={(e) => setVoucherCode(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={1} className="my-auto">
                  <Button onClick={onVoucherCheck} variant="link">
                    Apply
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {loading ? (
            <VoucherSpinner />
          ) : (
            <>
              {voucherValid && voucherValid.voucher_id !== 0 && (
                <VoucherValid
                  cartVoucherRate={cartVoucherRate}
                  cartVoucherTotal={cartVoucherTotal}
                  cartSub={cartSub}
                />
              )}
            </>
          )}
          {loading ? (
            <VoucherSpinner />
          ) : (
            <>
              {voucherValid && voucherValid.status === "Invalid" && (
                <VoucherInvalid />
              )}
            </>
          )}
          {loading ? (
            <VoucherSpinner />
          ) : (
            <>
              {voucherValid && voucherValid.status === "Expired" && (
                <VoucherExpired />
              )}
            </>
          )}
          <Card className="cart-part-card">
            <Card.Body>
              <Row>
                <Col lg={6} className="my-auto cart-fill d-none d-lg-block">
                  Subtotal
                </Col>
                <Col lg={3} className="my-auto">
                  Subtotal
                </Col>
                <Col lg={3} className="my-auto">
                  € {cartSub}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="cart-vat-card">
            <Card.Body>
              <Row>
                <Col lg={6} className="my-auto cart-fill d-none d-lg-block">
                  VAT
                </Col>
                <Col lg={3} className="my-auto">
                  VAT {cartTaxPercent} %
                </Col>
                <Col lg={3} className="my-auto">
                  € {cartTaxTotal}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="cart-total-card">
            <Card.Body>
              <Row>
                <Col lg={6} className="my-auto cart-fill d-none d-lg-block">
                  Total
                </Col>
                <Col lg={3} className="my-auto">
                  Total
                </Col>
                <Col lg={3} className="my-auto">
                  € {cartTotal}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Container
            className={
              (voucherValid && voucherValid.status === "Invalid") ||
              voucherValid.status === "Expired"
                ? "cart-checkout-section select-disabled"
                : "cart-checkout-section select-enabled"
            }
            fluid
          >
            {unifiedCheck ? (
              <Col md={12} className="cart-form-link">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => unifiedCheckoutHandler()}
                >
                  Check out
                </button>
              </Col>
            ) : (
              <Col md={12} className="cart-form-link">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => trialCheckoutHandler()}
                >
                  Trial fonts check out
                </button>
              </Col>
            )}
          </Container>
        </Container>
      </Container>
    </>
  );
}

export default Cart;
