import axios from "axios";
import { HOST, API } from "../constants/apiConstants";
import {
  HOME_LIST_REQUEST,
  HOME_LIST_SUCCESS,
  HOME_LIST_FAIL,
} from "../constants/homeConstants";

export const listHomes = () => async (dispatch) => {
  try {
    dispatch({ type: HOME_LIST_REQUEST });
    const endpoint = `${HOST}${API}`;
    const homeListUrl = `${endpoint}/homes/`;
    const { data } = await axios.get(homeListUrl);

    dispatch({ type: HOME_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: HOME_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
