export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_ACTIVATE_REQUEST = "USER_ACTIVATE_REQUEST";
export const USER_ACTIVATE_SUCCESS = "USER_ACTIVATE_SUCCESS";
export const USER_ACTIVATE_FAIL = "USER_ACTIVATE_FAIL";

export const USER_RESET_SUBMIT_REQUEST = "USER_RESET_SUBMIT_REQUEST";
export const USER_RESET_SUBMIT_SUCCESS = "USER_RESET_SUBMIT_SUCCESS";
export const USER_RESET_SUBMIT_FAIL = "USER_RESET_SUBMIT_FAIL";

export const USER_RESET_REQUEST = "USER_RESET_REQUEST";
export const USER_RESET_SUCCESS = "USER_RESET_SUCCESS";
export const USER_RESET_FAIL = "USER_RESET_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_DOWNLOAD_REQUEST = "USER_DOWNLOAD_REQUEST";
export const USER_DOWNLOAD_SUCCESS = "USER_DOWNLOAD_SUCCESS";
export const USER_DOWNLOAD_FAIL = "USER_DOWNLOAD_FAIL";
export const USER_DOWNLOAD_RESET = "USER_DOWNLOAD_RESET";

export const USER_UPDATE_DETAILS_REQUEST = "USER_UPDATE_DETAILS_REQUEST";
export const USER_UPDATE_DETAILS_SUCCESS = "USER_UPDATE_DETAILS_SUCCESS";
export const USER_UPDATE_DETAILS_FAIL = "USER_UPDATE_DETAILS_FAIL";
export const USER_UPDATE_DETAILS_RESET = "USER_UPDATE_DETAILS_RESET";

export const USER_UPDATE_PASSWORD_REQUEST = "USER_UPDATE_PASSWORD_REQUEST";
export const USER_UPDATE_PASSWORD_SUCCESS = "USER_UPDATE_PASSWORD_SUCCESS";
export const USER_UPDATE_PASSWORD_FAIL = "USER_UPDATE_PASSWORD_FAIL";
export const USER_UPDATE_PASSWORD_RESET = "USER_UPDATE_PASSWORD_RESET";
