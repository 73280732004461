import axios from "axios";
import { HOST, API } from "../constants/apiConstants";
import {
  CALC_LIST_REQUEST,
  CALC_LIST_SUCCESS,
  CALC_LIST_FAIL,
} from "../constants/calcConstants";

export const listCalcs = () => async (dispatch) => {
  try {
    dispatch({ type: CALC_LIST_REQUEST });
    const endpoint = `${HOST}${API}`;
    const calcListUrl = `${endpoint}/calcs/`;
    const { data } = await axios.get(calcListUrl);

    dispatch({ type: CALC_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CALC_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
