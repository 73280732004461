import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Offcanvas } from "react-bootstrap";
import { createGlobalStyle } from "styled-components";

// Global Styles
const WoffFont = createGlobalStyle`.${(props) =>
  props.font.font_class}{font-family: "${(props) => props.font.name}";}`;

const WoffPart = createGlobalStyle`.${(props) =>
  props.part.font_class}{font-family: "${(props) => props.part.name}";}`;

function Tester({ font }) {
  // State Etc
  const [parts, setParts] = useState([]);
  const [chars, setChars] = useState([]);
  const [textUnitWidth, setTextUnitWidth] = useState([]);
  const [count, setCount] = useState(1);
  const textUnit = useRef(null);

  // Off Canvas
  const backdropShowOpt = false;
  const bodyScrollOpt = true;
  const placementOpt = "bottom";

  // useEffects
  useEffect(() => {
    const textUnitWidth = textUnit.current.offsetWidth;
    setTextUnitWidth(textUnitWidth);
    setParts(
      font.parts.map((item) => ({
        ...item,
        b_alignment: "text-center",
        s_alignment: "text-center",
        b_size: textUnitWidth * item.b_adjust * 0.01,
        s_size: textUnitWidth * item.s_adjust * 0.01,
      }))
    );
  }, [font.parts]);

  useEffect(() => {
    const textUnitWidth = textUnit.current.offsetWidth;
    setTextUnitWidth(textUnitWidth);
    setChars(
      font.chars.map((item) => ({
        ...item,
        b_alignment: "text-center",
        s_alignment: "text-center",
        b_size: textUnitWidth * item.b_adjust * 0.01,
        s_size: textUnitWidth * item.s_adjust * 0.01,
      }))
    );
  }, [font.chars]);

  // Tester Controls
  const onAddTextLeftBig = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_alignment: "text-left",
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const onAddTextLeftBigChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          b_alignment: "text-left",
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  const onAddTextLeftSmall = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          s_alignment: "text-left",
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const onAddTextLeftSmallChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          s_alignment: "text-left",
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  const onAddTextCenterBig = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_alignment: "text-center",
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const onAddTextCenterBigChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          b_alignment: "text-center",
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  const onAddTextCenterSmall = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          s_alignment: "text-center",
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const onAddTextCenterSmallChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          s_alignment: "text-center",
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  const onAddTextRightBig = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_alignment: "text-end",
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const onAddTextRightBigChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          b_alignment: "text-end",
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  const onAddTextRightSmall = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          s_alignment: "text-end",
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const onAddTextRightSmallChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          s_alignment: "text-end",
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  function onSizeChangeBig(i, event) {
    const sizeValueUpdate = event.target.value;
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_size: sizeValueUpdate,
        };
      }
      return item;
    });
    setParts(updatedData);
  }

  function onSizeChangeBigChar(order, event) {
    const sizeValueUpdate = event.target.value;
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          b_size: sizeValueUpdate,
        };
      }
      return item;
    });
    setChars(updatedData);
  }

  function onSizeChangeSmall(i, event) {
    const sizeValueUpdate = event.target.value;
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          s_size: sizeValueUpdate,
        };
      }
      return item;
    });
    setParts(updatedData);
  }

  function onSizeChangeSmallChar(order, event) {
    const sizeValueUpdate = event.target.value;
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          s_size: sizeValueUpdate,
        };
      }
      return item;
    });
    setChars(updatedData);
  }

  function onLineHeightChangeBig(i, event) {
    const lineHeightValueUpdate = event.target.value;
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_lh: lineHeightValueUpdate,
        };
      }
      return item;
    });
    setParts(updatedData);
  }

  function onLineHeightChangeBigChar(order, event) {
    const lineHeightValueUpdate = event.target.value;
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          b_lh: lineHeightValueUpdate,
        };
      }
      return item;
    });
    setChars(updatedData);
  }

  function onTextChangeBig(i, event) {
    const newText = event.target.innerText;
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_text: newText,
        };
      }
      return item;
    });
    setParts(updatedData);
  }

  function onTextChangeBigChar(order, event) {
    const newText = event.target.innerText;
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          b_text: newText,
        };
      }
      return item;
    });
    setChars(updatedData);
  }

  function onTextChangeSmall(i, event) {
    const newText = event.target.innerText;
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          s_text: newText,
        };
      }
      return item;
    });
    setParts(updatedData);
  }

  function onTextChangeSmallChar(order, event) {
    const newText = event.target.innerText;
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          s_text: newText,
        };
      }
      return item;
    });
    setChars(updatedData);
  }

  const onApplyAllBig = useCallback(
    (i) => {
      const updatedPartData = parts.map((item, idx) => {
        if (idx !== i) {
          return {
            ...item,
            b_alignment: parts[i].b_alignment,
            b_size: parts[i].b_size,
            b_lh: parts[i].b_lh,
            b_text: parts[i].b_text,
          };
        }
        return item;
      });
      setParts(updatedPartData);
    },
    [parts]
  );

  const onApplyAllBigChar = useCallback(
    (order) => {
      const updatedData = parts.map((item) => {
        return {
          ...item,
          b_alignment: chars[order - 1].b_alignment,
          b_size: chars[order - 1].b_size,
          b_lh: chars[order - 1].b_lh,
          b_text: chars[order - 1].b_text,
        };
      });
      setParts(updatedData);
    },
    [parts, chars]
  );

  const onApplyAllSmall = useCallback(
    (i) => {
      const updatedPartData = parts.map((item, idx) => {
        if (idx !== i) {
          return {
            ...item,
            s_alignment: parts[i].s_alignment,
            s_size: parts[i].s_size,
            s_lh: parts[i].s_lh,
            s_text: parts[i].s_text,
          };
        }
        return item;
      });
      setParts(updatedPartData);
    },
    [parts]
  );

  const onApplyAllSmallChar = useCallback(
    (order) => {
      const updatedData = parts.map((item) => {
        return {
          ...item,
          s_alignment: chars[order - 1].s_alignment,
          s_size: chars[order - 1].s_size,
          s_lh: chars[order - 1].s_lh,
          s_text: chars[order - 1].s_text,
        };
      });
      setParts(updatedData);
    },
    [parts, chars]
  );

  function onResetAllBig() {
    const textUnitWidth = textUnit.current.offsetWidth;
    setParts(
      parts.map((item) => ({
        ...item,
        b_alignment: "text-center",
        b_size: textUnitWidth * item.b_adjust * 0.01,
        b_lh: item.b_lh_default,
        b_text: item.b_text_default,
      }))
    );
    setChars(
      chars.map((item) => ({
        ...item,
        b_alignment: "text-center",
        b_size: textUnitWidth * item.b_adjust * 0.01,
        b_lh: item.b_lh_default,
        b_text: item.b_text_default,
      }))
    );
  }

  function onResetAllSmall() {
    const textUnitWidth = textUnit.current.offsetWidth;
    setParts(
      parts.map((item) => ({
        ...item,
        s_alignment: "text-center",
        s_size: textUnitWidth * item.s_adjust * 0.01,
        s_lh: item.s_lh_default,
        s_text: item.s_text_default,
      }))
    );
    setChars(
      chars.map((item) => ({
        ...item,
        s_alignment: "text-center",
        s_size: textUnitWidth * item.s_adjust * 0.01,
        s_lh: item.s_lh_default,
        s_text: item.s_text_default,
      }))
    );
  }

  const onCharBig = (order) => {
    setCount(order);
    const updatedData = chars.map((item, idx) => {
      if (idx === order) {
        return {
          ...item,
          b_size: textUnitWidth * item.b_adjust * 0.01,
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  const toggleShowOptPart = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          itemShowOptPart: !item.itemShowOptPart,
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const handleCloseOptPart = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          itemShowOptPart: false,
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const toggleShowOptChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          itemShowOptChar: !item.itemShowOptChar,
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  const handleCloseOptChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          itemShowOptChar: false,
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  return (
    <>
      <WoffFont font={font} />
      {parts
        .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
        .map((part, i) => (
          <Container key={i} fluid className="tester-contain">
            <WoffPart part={part} />
            <Row>
              <Col className="d-md-block d-none" md={12}>
                <div className="font-name">{part.name}</div>
              </Col>
              {/* Big Part */}
              <Col className="unit-big d-md-block d-none" md={12}>
                <form className="form">
                  <div className="d-flex tester-nav justify-content-between">
                    <div className="weight-unit d-flex">
                      <div className="size-unit">
                        <input
                          type="range"
                          name="size"
                          min={part.b_size_min}
                          max={part.b_size_max}
                          value={part.b_size}
                          step={part.b_size_step}
                          className="size"
                          aria-label="Size"
                          onChange={(event) => onSizeChangeBig(i, event)}
                        ></input>
                      </div>
                      <div className="line-height-unit">
                        <input
                          type="range"
                          name="line-height"
                          min={part.b_lh_min}
                          max={part.b_lh_max}
                          value={part.b_lh}
                          step={part.b_lh_step}
                          className="line-height"
                          aria-label="Line Height"
                          onChange={(event) => onLineHeightChangeBig(i, event)}
                        ></input>
                      </div>
                      <div className="align-unit">
                        <button
                          type="button"
                          name="align"
                          className="btn btn-link text-left"
                          onClick={() => onAddTextLeftBig(i)}
                        >
                          <i className="bi bi-text-left"></i>
                        </button>
                        <button
                          type="button"
                          name="align"
                          className="btn btn-link text-center"
                          onClick={() => onAddTextCenterBig(i)}
                        >
                          <i className="bi bi-text-center"></i>
                        </button>
                        <button
                          type="button"
                          name="align"
                          className="btn btn-link text-right"
                          onClick={() => onAddTextRightBig(i)}
                        >
                          <i className="bi bi-text-right"></i>
                        </button>
                      </div>
                    </div>
                    <div className="options-unit">
                      <button
                        type="button"
                        className="btn btn-link apply-all"
                        onClick={() => onApplyAllBig(i)}
                      >
                        Apply All
                      </button>
                      <span className="pipe">&nbsp;|&nbsp;</span>
                      <button
                        type="button"
                        className="btn btn-link reset-all"
                        onClick={onResetAllBig}
                      >
                        Reset All
                      </button>
                    </div>
                  </div>
                  <div className="text-unit" role="none">
                    <p
                      contentEditable="true"
                      spellCheck="false"
                      suppressContentEditableWarning="true"
                      className={part.font_class + " " + part.b_alignment}
                      style={{
                        fontSize: part.b_size + "em",
                        lineHeight: part.b_lh + "em",
                        whiteSpace: "pre-line",
                      }}
                      onBlur={(event) => onTextChangeBig(i, event)}
                      role="none"
                    >
                      {part.b_text}
                    </p>
                  </div>
                </form>
              </Col>
              {/* Small Part */}
              <Col className="unit-small d-md-none" md={12}>
                <div className="tester-nav">
                  <div className="d-flex justify-content-between">
                    <div className="weight-unit">
                      <div className="weight">{part.name}</div>
                    </div>
                    <div className="options-unit">
                      <div>
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => toggleShowOptPart(i)}
                        >
                          Options
                        </button>
                      </div>
                      <Offcanvas
                        show={part.itemShowOptPart}
                        onHide={() => handleCloseOptPart(i)}
                        backdrop={backdropShowOpt}
                        scroll={bodyScrollOpt}
                        placement={placementOpt}
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>{part.name} Options</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div>
                            <button
                              type="button"
                              name="align"
                              className="btn btn-link text-left"
                              onClick={() => onAddTextLeftSmall(i)}
                            >
                              <i
                                className="bi bi-text-left"
                                style={{ fontSize: "2em" }}
                              ></i>
                            </button>
                            <button
                              type="button"
                              name="align"
                              className="btn btn-link text-center"
                              onClick={() => onAddTextCenterSmall(i)}
                            >
                              <i
                                className="bi bi-text-center"
                                style={{ fontSize: "2em" }}
                              ></i>
                            </button>
                            <button
                              type="button"
                              name="align"
                              className="btn btn-link text-right"
                              onClick={() => onAddTextRightSmall(i)}
                            >
                              <i
                                className="bi bi-text-right"
                                style={{ fontSize: "2em" }}
                              ></i>
                            </button>
                          </div>
                          <div className="mt-2">
                            <button
                              type="button"
                              className="btn btn-link apply-all"
                              onClick={() => onApplyAllSmall(i)}
                            >
                              Apply All
                            </button>
                            <span className="pipe">&nbsp;|&nbsp;</span>
                            <button
                              type="button"
                              className="btn btn-link reset-all"
                              onClick={onResetAllSmall}
                            >
                              Reset All
                            </button>
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>
                    </div>
                  </div>
                  <div className="size-unit">
                    <input
                      type="range"
                      name="size"
                      min={part.s_size_min}
                      max={part.s_size_max}
                      value={part.s_size}
                      step={part.s_size_step}
                      className="size"
                      aria-label="Size"
                      onChange={(event) => onSizeChangeSmall(i, event)}
                    ></input>
                  </div>
                </div>
                <div className="text-unit">
                  <p
                    contentEditable="true"
                    spellCheck="false"
                    suppressContentEditableWarning="true"
                    className={part.font_class + " " + part.s_alignment}
                    style={{
                      fontSize: part.s_size + "em",
                      lineHeight: part.s_lh + "em",
                      whiteSpace: "pre-line",
                    }}
                    onBlur={(event) => onTextChangeSmall(i, event)}
                    role="none"
                  >
                    {part.s_text}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        ))}
      {/* Chars */}
      <Container fluid className="tester-contain" ref={textUnit}>
        <Row>
          <Col className="d-md-block d-none" md={12}>
            <div className="font-name">{font.name} Basic Character Set</div>
          </Col>
        </Row>
        {chars
          .filter((char) => char.sequence === count)
          .map((fChar, i) => (
            <Row key={i}>
              {/* Big Char */}
              <Col className="unit-big d-md-block d-none" md={12}>
                <form className="form">
                  <div className="d-flex tester-nav justify-content-between">
                    <div className="weight-unit d-flex">
                      <div className="size-unit">
                        <input
                          type="range"
                          name="Char Size"
                          min={fChar.b_size_min}
                          max={fChar.b_size_max}
                          value={fChar.b_size}
                          step={fChar.b_size_step}
                          className="size"
                          aria-label="Size"
                          onChange={(event) =>
                            onSizeChangeBigChar(fChar.sequence, event)
                          }
                        ></input>
                      </div>
                      <div className="line-height-unit">
                        <input
                          type="range"
                          name="line-height"
                          min={fChar.b_lh_min}
                          max={fChar.b_lh_max}
                          value={fChar.b_lh}
                          step={fChar.b_lh_step}
                          className="line-height"
                          aria-label="Line Height"
                          onChange={(event) =>
                            onLineHeightChangeBigChar(fChar.sequence, event)
                          }
                        ></input>
                      </div>
                      <div className="align-unit">
                        <button
                          type="button"
                          name={fChar.sequence}
                          className="btn btn-link text-left"
                          onClick={() => onAddTextLeftBigChar(fChar.sequence)}
                        >
                          <i className="bi bi-text-left"></i>
                        </button>
                        <button
                          type="button"
                          name="align"
                          className="btn btn-link text-center"
                          onClick={() => onAddTextCenterBigChar(fChar.sequence)}
                        >
                          <i className="bi bi-text-center"></i>
                        </button>
                        <button
                          type="button"
                          name="align"
                          className="btn btn-link text-right"
                          onClick={() => onAddTextRightBigChar(fChar.sequence)}
                        >
                          <i className="bi bi-text-right"></i>
                        </button>
                      </div>
                      {chars.length > 1 && (
                        <div className="char-unit">
                          {chars
                            .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                            .map((char, i) => (
                              <span key={i}>
                                <button
                                  type="button"
                                  name="char"
                                  className="btn btn-link"
                                  onClick={() => onCharBig(char.sequence)}
                                >
                                  {char.abc}
                                </button>
                              </span>
                            ))}
                        </div>
                      )}
                    </div>
                    <div className="options-unit">
                      <button
                        type="button"
                        className="btn btn-link apply-all"
                        onClick={() => onApplyAllBigChar(fChar.sequence)}
                      >
                        Apply All
                      </button>
                      <span className="pipe">&nbsp;|&nbsp;</span>
                      <button
                        type="button"
                        className="btn btn-link reset-all"
                        onClick={onResetAllBig}
                      >
                        Reset All
                      </button>
                    </div>
                  </div>
                  <div className="text-unit" role="none">
                    <p
                      contentEditable="true"
                      spellCheck="false"
                      suppressContentEditableWarning="true"
                      className={fChar.font_class + " " + fChar.b_alignment}
                      style={{
                        fontSize: fChar.b_size + "em",
                        lineHeight: fChar.b_lh + "em",
                        whiteSpace: "pre-line",
                      }}
                      onBlur={(event) =>
                        onTextChangeBigChar(fChar.sequence, event)
                      }
                      role="none"
                    >
                      {fChar.b_text}
                    </p>
                  </div>
                </form>
              </Col>
              {/* Small Char */}
              <Col className="unit-small d-md-none" md={12}>
                <div className="tester-nav">
                  <div className="d-flex justify-content-between">
                    <div className="weight-unit">
                      {font.name} Basic Character Set
                    </div>
                    <div className="options-unit">
                      <div>
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => toggleShowOptChar(fChar.sequence)}
                        >
                          Options
                        </button>
                      </div>
                      <Offcanvas
                        show={fChar.itemShowOptChar}
                        onHide={() => handleCloseOptChar(fChar.sequence)}
                        backdrop={backdropShowOpt}
                        scroll={bodyScrollOpt}
                        placement={placementOpt}
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            {fChar.name} Options
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div>
                            {chars.length > 1 && (
                              <div className="char-unit">
                                {chars
                                  .sort((a, b) =>
                                    a.sequence > b.sequence ? 1 : -1
                                  )
                                  .map((char, i) => (
                                    <span key={i}>
                                      <button
                                        type="button"
                                        name="char"
                                        className="btn btn-link me-2"
                                        onClick={() => onCharBig(char.sequence)}
                                        id={char.char_id}
                                      >
                                        {char.abc}
                                      </button>
                                    </span>
                                  ))}
                              </div>
                            )}
                          </div>
                          <div className="mt-2">
                            <button
                              type="button"
                              className="btn btn-link text-left"
                              onClick={() =>
                                onAddTextLeftSmallChar(fChar.sequence)
                              }
                            >
                              <i
                                className="bi bi-text-left"
                                style={{ fontSize: "2em" }}
                              ></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-link text-center"
                              onClick={() =>
                                onAddTextCenterSmallChar(fChar.sequence)
                              }
                            >
                              <i
                                className="bi bi-text-center"
                                style={{ fontSize: "2em" }}
                              ></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-link text-right"
                              onClick={() =>
                                onAddTextRightSmallChar(fChar.sequence)
                              }
                            >
                              <i
                                className="bi bi-text-right"
                                style={{ fontSize: "2em" }}
                              ></i>
                            </button>
                          </div>
                          <div className="mt-2">
                            <button
                              type="button"
                              className="btn btn-link apply-all"
                              onClick={() =>
                                onApplyAllSmallChar(fChar.sequence)
                              }
                            >
                              Apply to All
                            </button>
                            <span className="pipe">&nbsp;|&nbsp;</span>
                            <button
                              type="button"
                              className="btn btn-link reset-all"
                              onClick={onResetAllSmall}
                            >
                              Reset All
                            </button>
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>
                    </div>
                  </div>
                  <div className="size-unit">
                    <input
                      type="range"
                      name="size"
                      min={fChar.s_size_min}
                      max={fChar.s_size_max}
                      value={fChar.s_size}
                      step={fChar.s_size_step}
                      className="size"
                      aria-label="Size"
                      onChange={(event) =>
                        onSizeChangeSmallChar(fChar.sequence, event)
                      }
                    ></input>
                  </div>
                </div>
                <div className="text-unit">
                  <p
                    contentEditable="true"
                    spellCheck="false"
                    suppressContentEditableWarning="true"
                    className={fChar.font_class + " " + fChar.s_alignment}
                    style={{
                      fontSize: fChar.s_size + "em",
                      lineHeight: fChar.s_lh + "em",
                      whiteSpace: "pre-line",
                    }}
                    onBlur={(event) =>
                      onTextChangeSmallChar(fChar.sequence, event)
                    }
                    role="none"
                  >
                    {fChar.s_text}
                  </p>
                </div>
              </Col>
            </Row>
          ))}
      </Container>
    </>
  );
}

export default Tester;
