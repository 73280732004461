import {
  ABOUT_LIST_REQUEST,
  ABOUT_LIST_SUCCESS,
  ABOUT_LIST_FAIL,
} from "../constants/aboutConstants";

export const aboutListReducer = (state = { abouts: [] }, action) => {
  switch (action.type) {
    case ABOUT_LIST_REQUEST:
      return { loading: true, abouts: [] };
    case ABOUT_LIST_SUCCESS:
      return { loading: false, abouts: action.payload };
    case ABOUT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
