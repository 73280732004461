import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import { activate } from "../../actions/userActions";
import { useParams } from "react-router-dom";
import Loader from "../main/Loader";
import Message from "../main/Message";

function ActivateInit() {
  // Selectors
  const userActivate = useSelector((state) => state.userActivate);
  const { error, loading, userInfo } = userActivate;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const { uid, token } = useParams();

  // useEffects
  useEffect(() => {
    if (userInfo) {
      navigate("/activate-complete", { replace: true });
    }
  }, [navigate, userInfo]);

  // On Activate
  const onActivate = () => {
    dispatch(activate(uid, token));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {error && <Message variant="danger">{error}</Message>}
          <Container fluid className="section-contain">
            <Row>
              <Col lg={12}>
                <h1 className="header">Account activation</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>Click below to activate your account.</Col>
              <Col lg={12} className="mt-2">
                <Button onClick={onActivate} variant="link">
                  Activate account
                </Button>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default ActivateInit;
