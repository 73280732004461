import {
  ORDER_TRIAL_INTENT_REQUEST,
  ORDER_TRIAL_INTENT_SUCCESS,
  ORDER_TRIAL_INTENT_FAIL,
  ORDER_TRIAL_INTENT_RESET,
  ORDER_INTENT_REQUEST,
  ORDER_INTENT_SUCCESS,
  ORDER_INTENT_FAIL,
  ORDER_INTENT_RESET,
  ORDER_TRIAL_REQUEST,
  ORDER_TRIAL_SUCCESS,
  ORDER_TRIAL_FAIL,
  ORDER_TRIAL_RESET,
  ORDER_PAYPAL_REQUEST,
  ORDER_PAYPAL_SUCCESS,
  ORDER_PAYPAL_FAIL,
  ORDER_PAYPAL_RESET,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_PROFILE_ORDERS_REQUEST,
  ORDER_PROFILE_ORDERS_SUCCESS,
  ORDER_PROFILE_ORDERS_FAIL,
  ORDER_PROFILE_ORDERS_RESET,
  ORDER_PROFILE_PURS_REQUEST,
  ORDER_PROFILE_PURS_SUCCESS,
  ORDER_PROFILE_PURS_FAIL,
  ORDER_PROFILE_PURS_RESET,
  ORDER_PROFILE_TRIALS_REQUEST,
  ORDER_PROFILE_TRIALS_SUCCESS,
  ORDER_PROFILE_TRIALS_FAIL,
  ORDER_PROFILE_TRIALS_RESET,
  ORDER_PROFILE_ADDS_REQUEST,
  ORDER_PROFILE_ADDS_SUCCESS,
  ORDER_PROFILE_ADDS_FAIL,
  ORDER_PROFILE_ADDS_RESET,
} from "../constants/orderConstants";

export const orderTrialIntentReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_TRIAL_INTENT_REQUEST:
      return { loading: true };
    case ORDER_TRIAL_INTENT_SUCCESS:
      return { loading: false, success: true };
    case ORDER_TRIAL_INTENT_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_TRIAL_INTENT_RESET:
      return {};
    default:
      return state;
  }
};

export const orderIntentReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_INTENT_REQUEST:
      return { loading: true };
    case ORDER_INTENT_SUCCESS:
      return { loading: false, success: true };
    case ORDER_INTENT_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_INTENT_RESET:
      return {};
    default:
      return state;
  }
};

export const orderTrialReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_TRIAL_REQUEST:
      return { loading: true };
    case ORDER_TRIAL_SUCCESS:
      return { loading: false, success: true };
    case ORDER_TRIAL_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_TRIAL_RESET:
      return {};
    default:
      return state;
  }
};

export const orderPayPalReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PAYPAL_REQUEST:
      return { loading: true };
    case ORDER_PAYPAL_SUCCESS:
      return { loading: false, success: true };
    case ORDER_PAYPAL_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_PAYPAL_RESET:
      return {};
    default:
      return state;
  }
};

export const orderDetailsReducer = (
  state = { loading: true, orderItems: [], billingInfo: {}, licenseHolder: {} },
  action
) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ORDER_DETAILS_SUCCESS:
      return { loading: false, order: action.payload };
    case ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const orderProfileOrdersReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_PROFILE_ORDERS_REQUEST:
      return { ...state, loading: true };
    case ORDER_PROFILE_ORDERS_SUCCESS:
      return { loading: false, orders: action.payload };
    case ORDER_PROFILE_ORDERS_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_PROFILE_ORDERS_RESET:
      return {
        orders: [],
      };
    default:
      return state;
  }
};

export const orderProfilePursReducer = (state = { purchases: [] }, action) => {
  switch (action.type) {
    case ORDER_PROFILE_PURS_REQUEST:
      return { ...state, loading: true };
    case ORDER_PROFILE_PURS_SUCCESS:
      return { loading: false, purchases: action.payload };
    case ORDER_PROFILE_PURS_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_PROFILE_PURS_RESET:
      return {
        purchases: [],
      };
    default:
      return state;
  }
};

export const orderProfileTrialsReducer = (state = { trials: [] }, action) => {
  switch (action.type) {
    case ORDER_PROFILE_TRIALS_REQUEST:
      return { ...state, loading: true };
    case ORDER_PROFILE_TRIALS_SUCCESS:
      return { loading: false, trials: action.payload };
    case ORDER_PROFILE_TRIALS_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_PROFILE_TRIALS_RESET:
      return {
        trials: [],
      };
    default:
      return state;
  }
};

export const orderProfileAddsReducer = (state = { additions: [] }, action) => {
  switch (action.type) {
    case ORDER_PROFILE_ADDS_REQUEST:
      return { ...state, loading: true };
    case ORDER_PROFILE_ADDS_SUCCESS:
      return { loading: false, additions: action.payload };
    case ORDER_PROFILE_ADDS_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_PROFILE_ADDS_RESET:
      return {
        additions: [],
      };
    default:
      return state;
  }
};
