import { Container, Row, Col } from "react-bootstrap";

function Use({ font }) {
  return (
    <Container fluid className="fonts-image">
      {font.uses.map((use, i) => (
        <Row key={i}>
          <Col md={12} className="fonts-image-col">
            <img
              src={"/images/font/" + font.slug + "/" + use.source}
              className="img-fluid"
              alt={use.name}
            />
          </Col>
        </Row>
      ))}
    </Container>
  );
}

export default Use;
