import axios from "axios";
import { HOST, API } from "../constants/apiConstants";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_SUCCESS,
  USER_ACTIVATE_FAIL,
  USER_RESET_SUBMIT_REQUEST,
  USER_RESET_SUBMIT_SUCCESS,
  USER_RESET_SUBMIT_FAIL,
  USER_RESET_REQUEST,
  USER_RESET_SUCCESS,
  USER_RESET_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_DOWNLOAD_REQUEST,
  USER_DOWNLOAD_SUCCESS,
  USER_DOWNLOAD_FAIL,
  USER_DOWNLOAD_RESET,
  USER_UPDATE_DETAILS_REQUEST,
  USER_UPDATE_DETAILS_SUCCESS,
  USER_UPDATE_DETAILS_FAIL,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_FAIL,
} from "../constants/userConstants";
import {
  CART_CLEAR_ITEMS,
  CART_TAX_RESET,
  CART_VAT_RESET,
  CART_COUNTRY_RESET,
  CART_BILLING_INFO_RESET,
  CART_VAT_VALID_RESET,
  CART_LICENSE_HOLDER_RESET,
  CART_PAYMENT_METHOD_RESET,
  CART_VOUCHER_VALID_RESET,
} from "../constants/cartConstants";
import {
  ORDER_TRIAL_INTENT_RESET,
  ORDER_INTENT_RESET,
  ORDER_TRIAL_RESET,
  ORDER_PAYPAL_RESET,
  ORDER_PROFILE_ORDERS_RESET,
  ORDER_PROFILE_PURS_RESET,
  ORDER_PROFILE_TRIALS_RESET,
  ORDER_PROFILE_ADDS_RESET,
} from "../constants/orderConstants";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const endpoint = `${HOST}${API}`;
    const userLoginUrl = `${endpoint}/users/login/`;
    const { data } = await axios.post(
      userLoginUrl,
      {
        username: email,
        password: password,
      },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("cartItems");
  localStorage.removeItem("tax");
  localStorage.removeItem("vat");
  localStorage.removeItem("countries");
  localStorage.removeItem("billingInfo");
  localStorage.removeItem("vatValid");
  localStorage.removeItem("licenseHolder");
  localStorage.removeItem("paymentMethod");
  localStorage.removeItem("voucherValid");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: USER_DOWNLOAD_RESET });
  dispatch({ type: CART_CLEAR_ITEMS });
  dispatch({ type: CART_TAX_RESET });
  dispatch({ type: CART_VAT_RESET });
  dispatch({ type: CART_COUNTRY_RESET });
  dispatch({ type: CART_BILLING_INFO_RESET });
  dispatch({ type: CART_VAT_VALID_RESET });
  dispatch({ type: CART_LICENSE_HOLDER_RESET });
  dispatch({ type: CART_PAYMENT_METHOD_RESET });
  dispatch({ type: CART_VOUCHER_VALID_RESET });
  dispatch({ type: ORDER_TRIAL_INTENT_RESET });
  dispatch({ type: ORDER_INTENT_RESET });
  dispatch({ type: ORDER_TRIAL_RESET });
  dispatch({ type: ORDER_PAYPAL_RESET });
  dispatch({ type: ORDER_PROFILE_ORDERS_RESET });
  dispatch({ type: ORDER_PROFILE_PURS_RESET });
  dispatch({ type: ORDER_PROFILE_TRIALS_RESET });
  dispatch({ type: ORDER_PROFILE_ADDS_RESET });
};

export const register = (name, email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const endpoint = `${HOST}${API}`;
    const userRegisterUrl = `${endpoint}/users/register/`;
    const { data } = await axios.post(
      userRegisterUrl,
      {
        name: name,
        email: email,
        password: password,
      },
      config
    );

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const activate = (uid, token) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ACTIVATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const endpoint = `${HOST}${API}`;
    const userActivateUrl = `${endpoint}/users/activate/${uid}/${token}/`;
    const { data } = await axios.post(
      userActivateUrl,
      {
        uid: uid,
        token: token,
      },
      config
    );

    dispatch({
      type: USER_ACTIVATE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_ACTIVATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const submitReset = (email) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESET_SUBMIT_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const endpoint = `${HOST}${API}`;
    const userSubmitResetUrl = `${endpoint}/users/reset-submit/`;
    const { data } = await axios.post(
      userSubmitResetUrl,
      {
        email: email,
      },
      config
    );

    dispatch({
      type: USER_RESET_SUBMIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_RESET_SUBMIT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const resetPassword = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESET_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const endpoint = `${HOST}${API}`;
    const userResetUrl = `${endpoint}/users/reset-password/`;
    const { data } = await axios.put(userResetUrl, user, config);

    dispatch({
      type: USER_RESET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_RESET_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const endpoint = `${HOST}${API}`;
    const userDetailsUrl = `${endpoint}/users/${id}/`;
    const { data } = await axios.get(userDetailsUrl, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const makeDownload = (slug, file) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DOWNLOAD_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const endpoint = `${HOST}${API}`;
    const userDownloadUrl = `${endpoint}/users/download/${slug}/${file}/`;
    const { data } = await axios.post(userDownloadUrl, config);

    dispatch({
      type: USER_DOWNLOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DOWNLOAD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateUserDetails = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const endpoint = `${HOST}${API}`;
    const updateDetailsUrl = `${endpoint}/users/profile/update/details/`;
    const { data } = await axios.put(updateDetailsUrl, user, config);

    dispatch({
      type: USER_UPDATE_DETAILS_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_UPDATE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateUserPassword = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PASSWORD_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const endpoint = `${HOST}${API}`;
    const updatePasswordUrl = `${endpoint}/users/profile/update/password/`;
    const { data } = await axios.put(updatePasswordUrl, user, config);

    dispatch({
      type: USER_UPDATE_PASSWORD_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
