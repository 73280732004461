import { Container, Row, Col, Alert } from "react-bootstrap";

const Message = ({ variant, children }) => {
  return (
    <Container fluid className="message-contain">
      <Row>
        <Col className="pt-3">
          <Alert variant={variant}>{children}</Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default Message;
