import React, { useState, useEffect } from "react";

const SpecSma = ({ home }) => {
  // State Etc
  const [count, setCount] = useState(0);
  const [specOver, setSpecOver] = useState(false);
  const [nameOver, setNameOver] = useState(false);

  // useEffects
  // specOver
  useEffect(() => {
    const body = document.querySelector("body");
    const nav = document.querySelector(".nav-contain");
    if (specOver) {
      body.className = home.slug + "-bg";
      nav.className =
        "nav-contain nav-sticky container-fluid " + home.slug + "-bg";
      setCount(1);
      const timer = setInterval(() => {
        setCount((prevCount) => (prevCount + 1) % home.specs.length);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      body.className = "section-bg";
      nav.className = "nav-contain nav-sticky container-fluid section-bg";
      setCount(0);
    }
  }, [specOver, home, home.specs]);

  // nameOver
  useEffect(() => {
    const body = document.querySelector("body");
    const nav = document.querySelector(".nav-contain");
    if (nameOver) {
      body.className = home.slug + "-bg";
      nav.className =
        "nav-contain nav-sticky container-fluid " + home.slug + "-bg";
    } else {
      body.className = "section-bg";
      nav.className = "nav-contain nav-sticky container-fluid section-bg";
    }
  }, [nameOver, home]);

  return (
    <>
      <div
        className="spec-contain"
        onMouseOver={() => setSpecOver(true)}
        onMouseOut={() => setSpecOver(false)}
      >
        {home.specs.length ? (
          <a href={"/font/" + home.slug}>
            <img
              src={"/images/home/" + home.slug + "/" + home.specs[count].source}
              className="img-fluid"
              alt={home.specs[count].slug}
            />
          </a>
        ) : (
          <a href={"/font/" + home.slug}>
            <img
              src={"/images/home/default/CakeType_home_CakeMono_06.svg"}
              className="img-fluid"
              alt="default"
            />
          </a>
        )}
      </div>
      <div
        className="home-font-info"
        onMouseOver={() => setNameOver(true)}
        onMouseOut={() => setNameOver(false)}
      >
        {home.status && (
          <>
            <span className="new">&nbsp;{home.status}&nbsp;</span>
            <span>&nbsp;</span>
          </>
        )}
        <a href={"/font/" + home.slug}>{home.name}</a>
        <br />
        {home.styles}
        <span className="pipe">|</span>
        {home.price}
        <br />
        {home.system}
      </div>
    </>
  );
};

export default SpecSma;
