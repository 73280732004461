import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  saveBillingInfo,
  saveCountries,
  saveCartTax,
  saveVatValid,
  saveLicenseHolder,
} from "../../actions/cartActions";
import VatLoader from "./VatLoader";
import CheckoutSummary from "./CheckoutSummary";
import CheckoutSteps from "./CheckoutSteps";
import CheckoutStepsLic from "./CheckoutStepsLic";

function BillingInfo() {
  // Selectors
  const cartCountry = useSelector((state) => state.cartCountry);
  const { countries } = cartCountry;

  const cartVatValid = useSelector((state) => state.cartVatValid);
  const { loading, vatValid } = cartVatValid;

  const cartBillingInfo = useSelector((state) => state.cartBillingInfo);
  const { billingInfo } = cartBillingInfo;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const [name, setName] = useState(billingInfo.name);
  const [company, setCompany] = useState(billingInfo.company);
  const [vatNumber, setVatNumber] = useState(billingInfo.vatNumber);
  const [vatRate, setVatRate] = useState(parseFloat(billingInfo.vatRate));
  const [addressOne, setAddressOne] = useState(billingInfo.addressOne);
  const [addressTwo, setAddressTwo] = useState(billingInfo.addressTwo);
  const [city, setCity] = useState(billingInfo.city);
  const [stateProvince, setStateProvince] = useState(billingInfo.stateProvince);
  const [zipPostalCode, setZipPostalCode] = useState(billingInfo.zipPostalCode);
  const [country, setCountry] = useState(billingInfo.country);
  const [countryCode, setCountryCode] = useState(billingInfo.countryCode);
  const [phone, setPhone] = useState(billingInfo.phone);
  const [licensee, setLicensee] = useState(false);
  const [countryList, setCountryList] = useState(countries);

  // useEffects
  useEffect(() => {
    const nav = document.querySelector(".nav-contain");
    nav.className = "nav-contain nav-sticky container-fluid section-bg";
  }, []);

  useEffect(() => {
    if (!countries) {
      dispatch(saveCountries());
      setCountryList(countries);
    }
  }, [dispatch, countries]);

  useEffect(() => {
    if (!company) {
      setCompany("");
    }
    if (!vatNumber) {
      setVatNumber("");
    }
    if (!addressTwo) {
      setAddressTwo("");
    }
  }, [company, vatNumber, addressTwo]);

  // On Country
  const onCountry = (e) => {
    var dataset = e.target.options[e.target.selectedIndex].dataset;
    setCountry(e.target.value);
    setCountryCode(dataset.code);
    setVatRate(dataset.rate);
  };

  // EULA Check
  const onLicenseeCheck = () => {
    const updatedLicensee = !licensee;
    setLicensee(updatedLicensee);
  };

  // On VAT Check
  const onVatCheck = () => {
    dispatch(saveVatValid(vatNumber));
  };

  // Submit Billing Licensee
  const submitBillingLicensee = (e) => {
    e.preventDefault();
    dispatch(
      saveBillingInfo({
        name,
        company,
        vatNumber,
        vatRate,
        addressOne,
        addressTwo,
        city,
        stateProvince,
        zipPostalCode,
        country,
        countryCode,
        phone,
      })
    );
    dispatch(
      saveLicenseHolder({
        name,
        company,
        addressOne,
        addressTwo,
        city,
        stateProvince,
        zipPostalCode,
        country,
        countryCode,
        phone,
        licensee,
      })
    );
    dispatch(saveVatValid(vatNumber));
    dispatch(saveCartTax({ vatRate }));
    navigate("/checkout/payment-method", { replace: true });
  };

  // Submit Billing
  const submitBilling = (e) => {
    e.preventDefault();
    dispatch(
      saveBillingInfo({
        name,
        company,
        vatNumber,
        vatRate,
        addressOne,
        addressTwo,
        city,
        stateProvince,
        zipPostalCode,
        country,
        countryCode,
        phone,
      })
    );
    dispatch(saveVatValid(vatNumber));
    dispatch(saveCartTax({ vatRate }));
    navigate("/checkout/license-holder", { replace: true });
  };

  return (
    <>
      <Container fluid className="section-contain">
        {licensee ? <CheckoutStepsLic step1 /> : <CheckoutSteps step1 />}
        <Form onSubmit={licensee ? submitBilling : submitBillingLicensee}>
          <Row>
            <Col lg={12}>
              <h1 className="header">Billing information</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="header-text">
              <CheckoutSummary />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Full name</Form.Label>
                    <Form.Control
                      required
                      type="name"
                      placeholder=""
                      value={name ? name : ""}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="company">
                    <Form.Label>Company</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={company ? company : ""}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="company">
                    <Form.Label>VAT number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={vatNumber ? vatNumber : ""}
                      onChange={(e) => setVatNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="addressOne">
                    <Form.Label>Address 1</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      value={addressOne ? addressOne : ""}
                      onChange={(e) => setAddressOne(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="addressTwo">
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={addressTwo ? addressTwo : ""}
                      onChange={(e) => setAddressTwo(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      value={city ? city : ""}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-4" controlId="stateProvince">
                    <Form.Label>State / province / region</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      value={stateProvince ? stateProvince : ""}
                      onChange={(e) => setStateProvince(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-4" controlId="zipPostalCode">
                    <Form.Label>Zip / postal code</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      value={zipPostalCode ? zipPostalCode : ""}
                      onChange={(e) => setZipPostalCode(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Select
                      required
                      onChange={(e) => onCountry(e)}
                      value={country ? country : ""}
                    >
                      <option value="">Select country</option>
                      {countryList.map((country, i) => (
                        <option
                          key={i}
                          value={country.name}
                          data-code={country.code}
                          data-rate={country.vat_rate}
                        >
                          {country.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      value={phone ? phone : ""}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          {vatNumber && (
            <>
              <Container className="validate-section" fluid>
                <Row>
                  <Col lg={6}>
                    <Button onClick={onVatCheck} variant="link">
                      Validate VAT number
                    </Button>
                  </Col>
                  {loading ? (
                    <VatLoader />
                  ) : (
                    <>
                      {vatValid.query && (
                        <Col lg={6}>
                          {vatValid.valid
                            ? vatValid.query + " is valid VAT number"
                            : vatValid.query + " is not a valid VAT number"}
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              </Container>
              <Container
                className={
                  vatValid.valid
                    ? "eula-section select-enabled"
                    : "eula-section select-disabled"
                }
                fluid
              >
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3" id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="I am licensing fonts for someone else."
                        onChange={onLicenseeCheck}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="cart-form-link">
                    <Button type="submit" variant="link">
                      Continue
                    </Button>
                  </Col>
                </Row>
              </Container>
            </>
          )}
          {!vatNumber && (
            <Container className="eula-section" fluid>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="I am licensing fonts for someone else."
                      onChange={onLicenseeCheck}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="cart-form-link">
                  <Button type="submit" variant="link">
                    Continue
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
        </Form>
      </Container>
    </>
  );
}

export default BillingInfo;
