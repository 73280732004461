import { Col, Spinner } from "react-bootstrap";

const VatLoader = () => {
  return (
    <Col lg={6}>
      <Spinner animation="grow" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Col>
  );
};

export default VatLoader;
