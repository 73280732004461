import axios from "axios";
import { HOST, API } from "../constants/apiConstants";
import {
  FONT_LIST_REQUEST,
  FONT_LIST_SUCCESS,
  FONT_LIST_FAIL,
  FONT_DETAIL_REQUEST,
  FONT_DETAIL_SUCCESS,
  FONT_DETAIL_FAIL,
} from "../constants/fontConstants";

export const listFonts = () => async (dispatch) => {
  try {
    dispatch({ type: FONT_LIST_REQUEST });
    const endpoint = `${HOST}${API}`;
    const fontListUrl = `${endpoint}/fonts/`;
    const { data } = await axios.get(fontListUrl);

    dispatch({ type: FONT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FONT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listFontDetail = (slug) => async (dispatch) => {
  try {
    dispatch({ type: FONT_DETAIL_REQUEST });
    const endpoint = `${HOST}${API}`;
    const fontDetailUrl = `${endpoint}/font/${slug}`;
    const { data } = await axios.get(fontDetailUrl);

    dispatch({ type: FONT_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FONT_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
