import { Row, Col, Card, Spinner } from "react-bootstrap";

const VoucherLoader = () => {
  return (
    <Card className="cart-part-card">
      <Card.Body>
        <Row>
          <Col>
            <Spinner animation="grow" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default VoucherLoader;
