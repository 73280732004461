import { Container, Row, Col } from "react-bootstrap";

function ActivateSent() {
  return (
    <Container fluid className="section-contain">
      <Row>
        <Col>
          <h1 className="header">Account activation</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>We’ve sent you an email to activate your account.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default ActivateSent;
