import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { listAbouts } from "../../actions/aboutActions";
import Loader from "../main/Loader";
import Message from "../main/Message";
import DOMPurify from "dompurify";

function About() {
  // Selectors
  const aboutList = useSelector((state) => state.aboutList);
  const { error, loading, abouts } = aboutList;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  // useEffects
  useEffect(() => {
    dispatch(listAbouts());
  }, [dispatch]);

  useEffect(() => {
    const nav = document.querySelector(".nav-contain");
    nav.className = "nav-contain nav-sticky container-fluid section-bg";
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Container fluid className="section-contain">
          <Row>
            <Col>
              <h1 className="header">About</h1>
            </Col>
          </Row>
          {abouts.map((about, i) => (
            <Row key={i}>
              {about.source && (
                <Col md={5}>
                  <img
                    src={"/images/about/" + about.source}
                    className="img-fluid"
                    alt={about.name}
                  />
                </Col>
              )}
              <Col md={12}>
                <p className="subhead">{about.name}</p>
              </Col>
              <Col md={9}>
                <div
                  className="main-text"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(about.desc, {
                      ADD_ATTR: ["target"],
                    }),
                  }}
                />
              </Col>
            </Row>
          ))}
        </Container>
      )}
    </>
  );
}

export default About;
