import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import CheckoutSteps from "./CheckoutSteps";

function OrderError() {
  // useEffects
  useEffect(() => {
    const nav = document.querySelector(".nav-contain");
    nav.className = "nav-contain nav-sticky container-fluid section-bg";
  }, []);

  return (
    <Container fluid className="section-contain">
      <CheckoutSteps step4 />
      <Row>
        <Col lg={12}>
          <h1 className="header">Order Error</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Link to="/account/profile">
            Sorry there was a problem with your order.
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default OrderError;
