import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listHomes } from "../../actions/homeActions";
import { Container } from "react-bootstrap";
import Loader from "../main/Loader";
import Message from "../main/Message";
import SpecBig from "./SpecBig";
import SpecSma from "./SpecSma";

function Home() {
  // Selectors
  const homeList = useSelector((state) => state.homeList);
  const { error, loading, homes } = homeList;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  // useEffects
  useEffect(() => {
    dispatch(listHomes());
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Container fluid className="home-contain d-md-block d-none">
            {homes
              .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
              .map((home, i) => (
                <div
                  key={i}
                  className={
                    homes.length === i + 1
                      ? "home-font-large home-last"
                      : "home-font-large"
                  }
                >
                  <SpecBig home={home} />
                </div>
              ))}
          </Container>
          <Container fluid className="home-contain d-md-none">
            {homes
              .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
              .map((home, i) => (
                <div
                  key={i}
                  className={
                    homes.length === i + 1
                      ? "home-font-large home-last"
                      : "home-font-large"
                  }
                >
                  <SpecSma home={home} />
                </div>
              ))}
          </Container>
        </>
      )}
    </>
  );
}

export default Home;
