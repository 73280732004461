import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { listFontDetail } from "../../actions/fontActions";
import { listCalcs } from "../../actions/calcActions";
import { useParams } from "react-router-dom";
import Loader from "../main/Loader";
import Message from "../main/Message";
import Calc from "./Calc";
import Info from "./Info";
import Tester from "./Tester";
import Use from "./Use";

function Font() {
  // Selectors
  const fontDetail = useSelector((state) => state.fontDetail);
  const { error, loading, font } = fontDetail;

  const calcList = useSelector((state) => state.calcList);
  const { calcs } = calcList;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  // State Etc
  const { slug } = useParams();

  // useEffects
  useEffect(() => {
    dispatch(listFontDetail(slug));
    dispatch(listCalcs());
  }, [dispatch, slug]);

  useEffect(() => {
    const nav = document.querySelector(".nav-contain");
    nav.className = "nav-contain nav-sticky container-fluid " + slug + "-bg";
  }, [slug]);

  return (
    <>
      <Helmet>
        <body className={slug + "-bg"} />
      </Helmet>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Container fluid className="fonts-contain">
            <Info font={font} />
            <Use font={font} />
            <Tester font={font} />
            <Calc font={font} calcs={calcs} />
          </Container>
        </>
      )}
    </>
  );
}

export default Font;
