import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { login } from "../../actions/userActions";
import Loader from "../main/Loader";
import Message from "../main/Message";

function LoginTrial() {
  // Selectors
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const redirect = "/checkout/trial/license-holder";

  // useEffects
  useEffect(() => {
    const nav = document.querySelector(".nav-contain");
    nav.className = "nav-contain nav-sticky container-fluid section-bg";
  }, []);

  useEffect(() => {
    if (userInfo) {
      navigate(redirect, { replace: true });
    }
  }, [navigate, userInfo, redirect]);

  // Submit
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {error && <Message variant="danger">{error}</Message>}
          <Container fluid className="section-contain">
            <Form onSubmit={submitHandler}>
              <Row>
                <Col md={12}>
                  <h1 className="header">Log In</h1>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder=""
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className="form-link">
                  <Button type="submit" variant="link">
                    Log in
                  </Button>
                </Col>
                <Col md={12} className="mt-3 form-link">
                  <Link to="/register">Create a new account</Link>
                </Col>
                <Col md={12} className="form-link">
                  <Link to="/reset-request">Forget your password?</Link>
                </Col>
              </Row>
            </Form>
          </Container>
        </>
      )}
    </>
  );
}

export default LoginTrial;
