import { Container, Row, Col } from "react-bootstrap";
import DOMPurify from "dompurify";

function Info({ font }) {
  return (
    <Container fluid className="fonts-info">
      <Row>
        <Col md={12}>
          <p className={"info-name " + font.font_class}>{font.name}</p>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <p>
            DESIGN
            <br />
            {font.design}
            <br />
            RELEASE
            <br />
            {font.release}
            <br />
            {font.assists.length > 0 && (
              <>
                TYPE DESIGN ASSISTANCE
                <br />
                {font.assists.map((assist, i) => (
                  <span key={i}>
                    <a href={assist.link} target="_blank" rel="noreferrer">
                      {assist.name}
                    </a>
                    <br />
                  </span>
                ))}
              </>
            )}
            <br />
            <a href={"/pdfs/" + font.slug + "/" + font.pdf}>
              Download PDF specimen
            </a>
          </p>
        </Col>
        <Col md={9} className="info-desc">
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(font.desc, {
                ADD_ATTR: ["target"],
              }),
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Info;
