import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card } from "react-bootstrap";
import { getOrderDetails } from "../../actions/orderActions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../main/Loader";
import Message from "../main/Message";

function Invoice() {
  // Selectors
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, error, loading } = orderDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const { orderId } = useParams();

  // useEffects
  useEffect(() => {
    const nav = document.querySelector(".nav-contain");
    nav.className = "nav-contain nav-sticky container-fluid section-bg";
  }, []);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login", { replace: true });
    } else {
      if (!order || order.order_id !== Number(orderId)) {
        dispatch(getOrderDetails(orderId));
      }
    }
  }, [dispatch, navigate, userInfo, order, orderId]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Container fluid className="section-contain">
            <div className="invoice-header">
              <Row>
                <Col md={12}>
                  <div className="invoice">
                    <h1 className="header">Invoice # {order.order_ref}</h1>
                  </div>
                </Col>
              </Row>
              {order.method !== "Trial" ? (
                <Row>
                  <Col md={4}>
                    <p className="subhead">Ordered by</p>
                    <p>{order.user.name}</p>
                    <p>{order.user.email}</p>
                  </Col>
                  <Col md={4}>
                    <p className="subhead">Billed to</p>
                    <p>{order.billingInfo.name}</p>
                    {order.billingInfo.company && (
                      <p>{order.billingInfo.company}</p>
                    )}
                    {order.billingInfo.vat_number && (
                      <p>{order.billingInfo.vat_number}</p>
                    )}
                    <p>{order.billingInfo.address_one}</p>
                    {order.billingInfo.address_two && (
                      <p>{order.billingInfo.address_two}</p>
                    )}
                    <p>{order.billingInfo.city}</p>
                    <p>{order.billingInfo.state_province}</p>
                    <p>{order.billingInfo.zip_postal_code}</p>
                    <p>{order.billingInfo.country}</p>
                  </Col>
                  <Col md={4}>
                    <p className="subhead">Licensed to</p>
                    <p>{order.licenseHolder.name}</p>
                    {order.licenseHolder.company && (
                      <p>{order.licenseHolder.company}</p>
                    )}
                    <p>{order.licenseHolder.address_one}</p>
                    {order.licenseHolder.address_two && (
                      <p>{order.licenseHolder.address_two}</p>
                    )}
                    <p>{order.licenseHolder.city}</p>
                    <p>{order.licenseHolder.state_province}</p>
                    <p>{order.licenseHolder.zip_postal_code}</p>
                    <p>{order.licenseHolder.country}</p>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md={6}>
                    <p className="subhead">Ordered by</p>
                    <p>{order.user.name}</p>
                    <p>{order.user.email}</p>
                  </Col>
                  <Col md={6}>
                    <p className="subhead">Licensed to</p>
                    <p>{order.licenseHolder.name}</p>
                    {order.licenseHolder.company && (
                      <p>{order.licenseHolder.company}</p>
                    )}
                    <p>{order.licenseHolder.address_one}</p>
                    {order.licenseHolder.address_two && (
                      <p>{order.licenseHolder.address_two}</p>
                    )}
                    <p>{order.licenseHolder.city}</p>
                    <p>{order.licenseHolder.state_province}</p>
                    <p>{order.licenseHolder.zip_postal_code}</p>
                    <p>{order.licenseHolder.country}</p>
                  </Col>
                </Row>
              )}
            </div>
            {order.method !== "Trial" ? (
              <Container className="invoice-section" fluid>
                <Row>
                  <Col md={12}>
                    {order.orderItems.map((orderItem, i) => (
                      <Card
                        key={i}
                        className={
                          i === 0 ? "invoice-card" : "invoice-part-card"
                        }
                      >
                        <Card.Body>
                          <Row>
                            <Col xs={6} className="my-auto">
                              {orderItem.name}
                            </Col>
                            <Col xs={3} className="my-auto">
                              {orderItem.license}
                            </Col>
                            <Col xs={3} className="my-auto text-end">
                              € {orderItem.price}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ))}
                    {order.voucher_code && (
                      <Card className="invoice-voucher-card">
                        <Card.Body>
                          <Row>
                            <Col xs={6} className="my-auto">
                              Voucher{" "}
                              {parseInt(100 * parseFloat(order.voucher_rate))} %
                            </Col>
                            <Col xs={3} className="my-auto cart-fill">
                              Voucher
                            </Col>
                            <Col xs={3} className="my-auto text-end">
                              &minus; € {order.voucher_amount}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    )}
                    <Card className="invoice-subtotal-card">
                      <Card.Body>
                        <Row>
                          <Col xs={6} className="my-auto">
                            Subtotal
                          </Col>
                          <Col xs={3} className="my-auto cart-fill">
                            Subtotal
                          </Col>
                          <Col xs={3} className="my-auto text-end">
                            € {order.subtotal}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="invoice-part-card">
                      <Card.Body>
                        <Row>
                          {parseFloat(order.billingInfo.vat_rate) !== 0 &&
                          parseFloat(order.tax) === 0 &&
                          order.billingInfo.country !== "Netherlands" ? (
                            <Col xs={6} className="my-auto">
                              Reverse charge VAT
                            </Col>
                          ) : (
                            <Col xs={6} className="my-auto">
                              VAT {parseInt(100 * parseFloat(order.vat_rate))} %
                            </Col>
                          )}
                          <Col xs={3} className="my-auto cart-fill">
                            VAT
                          </Col>
                          <Col xs={3} className="my-auto text-end">
                            € {order.tax}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="invoice-total-card">
                      <Card.Body>
                        <Row>
                          <Col xs={6} className="my-auto">
                            Total
                          </Col>
                          <Col xs={3} className="my-auto cart-fill">
                            Total
                          </Col>
                          <Col xs={3} className="my-auto text-end">
                            € {order.total}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            ) : (
              <Container className="invoice-section" fluid>
                <Row>
                  <Col md={12}>
                    {order.orderItems.map((orderItem, i) => (
                      <Card
                        key={i}
                        className={
                          i === 0 ? "invoice-card" : "invoice-part-card"
                        }
                      >
                        <Card.Body>
                          <Row>
                            <Col xs={6} className="my-auto">
                              {orderItem.name}
                            </Col>
                            <Col xs={3} className="my-auto">
                              {orderItem.license}
                            </Col>
                            <Col xs={3} className="my-auto text-end">
                              € {orderItem.price}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ))}
                    <Card className="invoice-total-card">
                      <Card.Body>
                        <Row>
                          <Col xs={6} className="my-auto">
                            Total
                          </Col>
                          <Col xs={3} className="my-auto cart-fill">
                            Total
                          </Col>
                          <Col xs={3} className="my-auto text-end">
                            € {order.total}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            )}
            <div className="invoice-footer">
              <Row>
                <Col md={12}>
                  <h1 className="header">Thanks for licensing!</h1>
                  <p>
                    CakeType is part of CakeLab BV
                    <br />
                    Johannes Buijslaan 36
                    <br />
                    5652 NL Eindhoven
                    <br />
                    The Netherlands
                    <br />
                    <br />
                    www.caketype.com
                    <br />
                    hello@caketype.com
                    <br />
                    Chamber of commerce # 66961866
                    <br />
                    VAT # NL856771685B01
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </>
      )}
    </>
  );
}

export default Invoice;
