import { Container, Row, Col } from "react-bootstrap";

function ResetSent() {
  return (
    <Container fluid className="section-contain">
      <Row>
        <Col>
          <h1 className="header">Reset Password</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>We’ve emailed you instructions for resetting your password.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetSent;
