import axios from "axios";
import { HOST, API } from "../constants/apiConstants";
import {
  SUPPORT_LIST_REQUEST,
  SUPPORT_LIST_SUCCESS,
  SUPPORT_LIST_FAIL,
} from "../constants/supportConstants";

export const listSupports = () => async (dispatch) => {
  try {
    dispatch({ type: SUPPORT_LIST_REQUEST });
    const endpoint = `${HOST}${API}`;
    const supportListUrl = `${endpoint}/supports/`;
    const { data } = await axios.get(supportListUrl);

    dispatch({ type: SUPPORT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SUPPORT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
