import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createTrialOrder } from "../../actions/orderActions";

function TrialSubmit({ intentUuid }) {
  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Submit
  const submitTrialOrder = () => {
    dispatch(createTrialOrder(intentUuid));
    navigate("/checkout/complete", { replace: true });
  };

  return (
    <>
      <button type="submit" className="cc-button" onClick={submitTrialOrder}>
        Submit order
      </button>
    </>
  );
}

export default TrialSubmit;
