import { Breadcrumb } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function CheckoutSteps({ step1, step2, step3, step4, step5 }) {
  return (
    <>
      <Breadcrumb>
        Checkout&nbsp;|&nbsp;
        {step1 ? (
          <LinkContainer to="/checkout/billing-info">
            <Breadcrumb.Item>Billing info</Breadcrumb.Item>
          </LinkContainer>
        ) : (
          <LinkContainer to="/checkout/billing-info">
            <Breadcrumb.Item className="breadcrumb-disabled">
              Billing info
            </Breadcrumb.Item>
          </LinkContainer>
        )}
        {step2 ? (
          <LinkContainer to="/checkout/license-holder">
            <Breadcrumb.Item>License holder</Breadcrumb.Item>
          </LinkContainer>
        ) : (
          <LinkContainer to="/checkout/license-holder">
            <Breadcrumb.Item className="breadcrumb-disabled">
              License holder
            </Breadcrumb.Item>
          </LinkContainer>
        )}
        {step3 ? (
          <LinkContainer to="/checkout/payment-method">
            <Breadcrumb.Item>Payment method</Breadcrumb.Item>
          </LinkContainer>
        ) : (
          <LinkContainer to="/checkout/payment-method">
            <Breadcrumb.Item className="breadcrumb-disabled">
              Payment method
            </Breadcrumb.Item>
          </LinkContainer>
        )}
        {step4 ? (
          <LinkContainer to="/checkout/payment">
            <Breadcrumb.Item>Payment</Breadcrumb.Item>
          </LinkContainer>
        ) : (
          <LinkContainer to="/checkout/payment">
            <Breadcrumb.Item className="breadcrumb-disabled">
              Payment
            </Breadcrumb.Item>
          </LinkContainer>
        )}
        {step5 ? (
          <LinkContainer to="/">
            <Breadcrumb.Item>Complete</Breadcrumb.Item>
          </LinkContainer>
        ) : (
          <LinkContainer to="/">
            <Breadcrumb.Item className="breadcrumb-disabled">
              Complete
            </Breadcrumb.Item>
          </LinkContainer>
        )}
      </Breadcrumb>
    </>
  );
}

export default CheckoutSteps;
