import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { listSupports } from "../../actions/supportActions";
import { HashLink as Link } from "react-router-hash-link";
import Loader from "../main/Loader";
import Message from "../main/Message";
import DOMPurify from "dompurify";

function Support() {
  // Selectors
  const supportList = useSelector((state) => state.supportList);
  const { error, loading, supports } = supportList;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  // useEffects
  useEffect(() => {
    dispatch(listSupports());
  }, [dispatch]);

  useEffect(() => {
    const nav = document.querySelector(".nav-contain");
    nav.className = "nav-contain nav-sticky container-fluid section-bg";
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Container fluid className="section-contain">
          <div className="support-head">
            <Row>
              <Col>
                <h1 className="header">Support</h1>
              </Col>
            </Row>
            {supports
              .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
              .map((support, i) => (
                <Row key={i} className="support-head-info">
                  <Col md={12}>
                    <p className="subhead">{support.name}</p>
                    {support.sections
                      .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                      .map((section, i) => (
                        <p key={i}>
                          <Link to={"#" + section.anchor}>{section.name}</Link>
                        </p>
                      ))}
                  </Col>
                </Row>
              ))}
          </div>
          <div className="support-section">
            {supports
              .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
              .map((support, i) => (
                <div
                  key={i}
                  className={
                    supports.length === i + 1
                      ? "support-section-info-last"
                      : "support-section-info"
                  }
                >
                  {support.sections
                    .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                    .map((section, i) => (
                      <div
                        id={section.anchor}
                        key={i}
                        className={
                          support.sections.length === i + 1
                            ? "support-section-subheads-last"
                            : "support-section-subheads"
                        }
                      >
                        <Col md={9}>
                          <p className="support-section-name">{section.name}</p>
                          <div
                            className="support-section-desc"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(section.desc, {
                                ADD_ATTR: ["target"],
                              }),
                            }}
                          />
                          {section.link_text && (
                            <p className="support-section-link">
                              <a href={section.link_url}>{section.link_text}</a>
                            </p>
                          )}
                        </Col>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </Container>
      )}
    </>
  );
}

export default Support;
