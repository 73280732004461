import axios from "axios";
import { HOST, API } from "../constants/apiConstants";
import {
  MGMT_ORDER_REQUEST,
  MGMT_ORDER_SUCCESS,
  MGMT_ORDER_FAIL,
} from "../constants/mgmtConstants";

export const getMgmtOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MGMT_ORDER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const endpoint = `${HOST}${API}`;
    const getOrderUrl = `${endpoint}/mgmt/order/${id}/`;
    const { data } = await axios.get(getOrderUrl, config);

    dispatch({
      type: MGMT_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MGMT_ORDER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
