import { Container, Row, Col, Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <Container fluid className="loader-contain">
      <Row>
        <Col className="text-center pt-3">
          <Spinner animation="grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      </Row>
    </Container>
  );
};

export default Loader;
