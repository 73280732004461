import React, { useState } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createPayPalOrder } from "../../actions/orderActions";

function PayPalSubmit({ cart, intentUuid }) {
  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const [paidFor, setPaidFor] = useState(false);
  const [payError, setPayError] = useState(null);

  // Handle Approve
  const handleApprove = (intentUuid, orderId) => {
    dispatch(createPayPalOrder(intentUuid, orderId));
    setPaidFor(true);
  };

  if (paidFor) {
    console.log("PayPal Checkout Success");
  }

  if (payError) {
    navigate("/checkout/order-error", { replace: true });
  }
  return (
    <>
      <PayPalButtons
        style={{
          color: "black",
          layout: "horizontal",
          height: 48,
          tagline: false,
        }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: cart.total,
                amount: { value: cart.total },
              },
            ],
            application_context: {
              shipping_preference: "NO_SHIPPING",
            },
          });
        }}
        onApprove={async (data, actions) => {
          const order = await actions.order.capture();
          console.log("order", order);
          handleApprove(intentUuid, data.orderID);
          navigate("/checkout/complete", { replace: true });
        }}
        onCancel={() => {
          navigate("/checkout/payment-paypal", { replace: true });
        }}
        onError={(err) => {
          setPayError(err);
          console.error("PayPal Checkout onError", err);
        }}
      />
    </>
  );
}

export default PayPalSubmit;
