import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { submitReset } from "../../actions/userActions";
import Loader from "../main/Loader";
import Message from "../main/Message";

function ResetSubmit() {
  // Selectors
  const userResetSubmit = useSelector((state) => state.userResetSubmit);
  const { error, loading, success } = userResetSubmit;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const [email, setEmail] = useState("");
  const location = useLocation();
  const redirect = location.search
    ? location.search.split("=")[1]
    : "/reset-sent";

  // useEffects
  useEffect(() => {
    const nav = document.querySelector(".nav-contain");
    nav.className = "nav-contain nav-sticky container-fluid section-bg";
  }, []);

  // useEffects
  useEffect(() => {
    if (success) {
      navigate(redirect, { replace: true });
    }
  }, [navigate, success, redirect]);

  // Submit
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(submitReset(email));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {error && <Message variant="danger">{error}</Message>}
          <Container fluid className="section-contain">
            <Form onSubmit={submitHandler}>
              <Row>
                <Col md={12}>
                  <h1 className="header">Reset password</h1>
                </Col>
                <Col md={12}>
                  <p>Please enter your email to reset your password.</p>
                </Col>
                <Col className="mt-3" md={6}>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className="form-link">
                  <Button type="submit" variant="link">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </>
      )}
    </>
  );
}

export default ResetSubmit;
