import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saveCountries, saveLicenseHolder } from "../../actions/cartActions";
import CheckoutSummary from "./CheckoutSummary";
import TrialSteps from "./TrialSteps";

function TrialLicenseHolder() {
  // Selectors
  const cartCountry = useSelector((state) => state.cartCountry);
  const { countries } = cartCountry;

  const cartLicenseHolder = useSelector((state) => state.cartLicenseHolder);
  const { licenseHolder } = cartLicenseHolder;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const [name, setName] = useState(licenseHolder.name);
  const [company, setCompany] = useState(licenseHolder.company);
  const [addressOne, setAddressOne] = useState(licenseHolder.addressOne);
  const [addressTwo, setAddressTwo] = useState(licenseHolder.addressTwo);
  const [city, setCity] = useState(licenseHolder.city);
  const [stateProvince, setStateProvince] = useState(
    licenseHolder.stateProvince
  );
  const [zipPostalCode, setZipPostalCode] = useState(
    licenseHolder.zipPostalCode
  );
  const [country, setCountry] = useState(licenseHolder.country);
  const [countryCode, setCountryCode] = useState(licenseHolder.countryCode);
  const [phone, setPhone] = useState(licenseHolder.phone);
  const [licensee, setLicensee] = useState(licenseHolder.licensee);
  const [countryList, setCountryList] = useState(countries);

  // useEffects
  useEffect(() => {
    const nav = document.querySelector(".nav-contain");
    nav.className = "nav-contain nav-sticky container-fluid section-bg";
  }, []);

  useEffect(() => {
    if (!countries) {
      dispatch(saveCountries());
      setCountryList([countries]);
    }
  }, [dispatch, countries]);

  useEffect(() => {
    if (!company) {
      setCompany("");
    }
    if (!addressTwo) {
      setAddressTwo("");
    }
    if (!licensee) {
      setLicensee(true);
    }
  }, [company, addressTwo, licensee]);

  // On Country
  const onCountry = (e) => {
    var dataset = e.target.options[e.target.selectedIndex].dataset;
    setCountry(e.target.value);
    setCountryCode(dataset.code);
  };

  // Submit License Holder
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      saveLicenseHolder({
        name,
        company,
        addressOne,
        addressTwo,
        city,
        stateProvince,
        zipPostalCode,
        country,
        countryCode,
        phone,
        licensee,
      })
    );
    navigate("/checkout/trial/submit-order", { replace: true });
  };

  return (
    <Container fluid className="section-contain">
      <TrialSteps step1 />
      <Form onSubmit={submitHandler}>
        <Row>
          <Col lg={12}>
            <h1 className="header">License holder information</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="header-text">
            <CheckoutSummary />
          </Col>
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    required
                    type="name"
                    placeholder=""
                    value={name ? name : ""}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="company">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={company ? company : ""}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="addressOne">
                  <Form.Label>Address 1</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder=""
                    value={addressOne ? addressOne : ""}
                    onChange={(e) => setAddressOne(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="addressTwo">
                  <Form.Label>Address 2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={addressTwo ? addressTwo : ""}
                    onChange={(e) => setAddressTwo(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder=""
                    value={city ? city : ""}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-4" controlId="stateProvince">
                  <Form.Label>State / province / region</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder=""
                    value={stateProvince ? stateProvince : ""}
                    onChange={(e) => setStateProvince(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-4" controlId="zipPostalCode">
                  <Form.Label>Zip / postal code</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder=""
                    value={zipPostalCode ? zipPostalCode : ""}
                    onChange={(e) => setZipPostalCode(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    required
                    onChange={(e) => onCountry(e)}
                    value={country ? country : ""}
                  >
                    <option value="">Select country</option>
                    {countryList.map((country, i) => (
                      <option
                        key={i}
                        value={country.name}
                        data-code={country.code}
                        data-rate={country.vat_rate}
                      >
                        {country.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder=""
                    value={phone ? phone : ""}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Container className="eula-section" fluid>
          <Row>
            <Col md={12} className="cart-form-link">
              <Button type="submit" variant="link">
                Continue
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </Container>
  );
}

export default TrialLicenseHolder;
